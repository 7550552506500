import React, { useEffect } from "react"
import EditorText from '../../../../components/editor-components/EditorText'
import EditorInput from '../../../../components/editor-components/EditorInput'
import EditorRating from "../../../../components/editor-components/EditorRating"
import EditorImg from "../../../../components/editor-components/EditorImg"
import EditorPrimaryBtn from "../../../../components/editor-components/EditorPrimaryBtn"
import EditorQr from "../../../../components/editor-components/EditorQr"

const EditorFieldParser = ({ fieldTypeCount, setFieldTypeCount, field, userData, setUserData, id, overallStyle, deleteField, setFieldType, ...props }) => {
  const fieldType = field?.type
  const fieldStyle = field?.style
  const fieldId = field?.id
  const isRequired = field?.required

  useEffect(() => {
    fieldTypeCount && countFieldTypes()
  }, [fieldId])

  const countFieldTypes = () => {
    let currentFieldTypeCount = { ...fieldTypeCount }
    currentFieldTypeCount = { [fieldType]: currentFieldTypeCount[fieldType] + 1 }
    setFieldTypeCount(prevSchema => ({ ...prevSchema, ...currentFieldTypeCount }))
  }

  if (fieldType === 'text') {
    return <EditorText
      id={fieldId}
      field={field}
      label={field.label}
      fieldType='Text'
      fieldStyle={fieldStyle}
      overallStyle={overallStyle}
      setField={setUserData}
      deleteField={deleteField}
    />
  }

  if (fieldType === 'image') {
    return <EditorImg
      id={fieldId}
      field={field}
      fieldType='Image'
      fieldStyle={fieldStyle}
      overallStyle={overallStyle}
      setField={setUserData}
      deleteField={deleteField}
    />
  }

  if (fieldType === 'name') {
    return <EditorInput
      id={fieldId}
      type='input'
      field={field}
      label={field.label}
      fieldType='Name'
      isRequired={isRequired}
      fieldStyle={fieldStyle}
      overallStyle={overallStyle}
      setField={setUserData}
      deleteField={deleteField}
    />
  }

  if (fieldType === 'email') {
    return <EditorInput
      id={fieldId}
      type='input'
      label={field.label}
      field={field}
      isRequired={isRequired}
      fieldType='Email'
      fieldStyle={fieldStyle}
      overallStyle={overallStyle}
      setField={setUserData}
      deleteField={deleteField}
    />
  }

  if (fieldType === 'comment') {
    return <EditorInput
      id={fieldId}
      type='textarea'
      field={field}
      label={field.label}
      isRequired={isRequired}
      fieldType='Comment'
      fieldStyle={fieldStyle}
      overallStyle={overallStyle}
      setField={setUserData}
      deleteField={deleteField}
    />
  }

  if (fieldType === 'rating') {
    return <EditorRating
      field={field}
      id={fieldId}
      fieldType='Rating'
      label={field.label}
      isRequired={isRequired}
      fieldStyle={fieldStyle}
      overallStyle={overallStyle}
      setField={setUserData}
      deleteField={deleteField}
    />
  }

  if (fieldType === 'navigation') {
    return <EditorPrimaryBtn
      id={fieldId}
      field={field}
      label={field.label}
      fieldType='Navigation Button'
      fieldStyle={fieldStyle}
      overallStyle={overallStyle}
      setField={setUserData}
    />
  }

  if (fieldType === 'qr') {
    return <EditorQr
      id={fieldId}
      field={field}
      fieldStyle={fieldStyle}
      overallStyle={overallStyle}
      setField={setUserData}
    />
  }

}

export default EditorFieldParser