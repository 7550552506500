import React, { useState } from "react"
import './Login.scss'
import StyledInput from "../../components/inputs/StyledInput"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined'
import { connect } from 'react-redux'
import { setLoggedIn } from '../../redux/ducks/user'
import { NavLink, useNavigate } from "react-router-dom"
import StyledCheckbox from "../../components/checkbox/StyledCheckbox"
import TextLink from "../../components/buttons/TextLink"
import StyledInputIcon from "../../components/inputs/StyledInputIcon"
import Typography from '../../components/typography/Typography'
import SecondaryButton from '../../components/buttons/SecondaryButton'
import Logo from "../../components/logo/Logo"
import { login } from "../../api/user"
import { setError } from '../../redux/ducks/snackbar'

const mapStateToProps = (state, ownProps) => ({
  user: state.user
})

const mapDispatchToProps = {
  setLoggedIn,
  setError
}

const connectToStore = connect(
  mapStateToProps,
  mapDispatchToProps
)

const Login = ({ user, setLoggedIn, setError, ...props }) => {
  const [showPass, setShowPass] = useState(false)
  const [disabledNav, setDisabledNav] = useState(false)
  const [userInfo, setUserInfo] = useState({ email: '', password: '', rememberMe: false })
  const navigate = useNavigate()

  const onSubmit = (e) => {
    e.preventDefault()
    setDisabledNav(true)
    login(userInfo)
      .then((res) => {
        window.localStorage.setItem('jwt_access_token', res.data.access)
        window.localStorage.setItem('jwt_refresh_token', res.data.refresh)
        setLoggedIn(true)
        navigate('/')
      })
      .catch((err) => {
        const error = err.response.data.detail || 'Server issue'
        setError(`There was an error when logging in! \n ${error}`)
      })
      .finally(() => {
        setDisabledNav(false)
      })
  }

  const enabled = !disabledNav && (userInfo.password.length > 1 && userInfo.email.length > 1)

  return (
    <div className="login-container">
      <div className="login-left">
        <div className="login-left-content">
          <div className="logo"><Logo /></div>
          <div className="middle-message">
            <Typography variant='h1'>Welcome back!</Typography>
            <Typography variant='h3'>Please log in to continue your journey!</Typography>
          </div>
          <div className="create-account">
            <Typography variant='subheading1'>Don't have an account yet?</Typography>
            <NavLink to='/register/'>
              <SecondaryButton variant='light'>Create account</SecondaryButton>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="login-right">
        <div className="title">
          <Typography variant='h1'>Login</Typography>
        </div>
        <form onSubmit={onSubmit}>
          <div className="login-fields">
            <StyledInput
              label='Email'
              value={userInfo.email}
              onChange={e => setUserInfo({ ...userInfo, email: e.target.value })}
              type='email'
              className='login-text-username'
              placeholder='Email...'
              required
            />
            <StyledInputIcon
              label='Password'
              required
              value={userInfo.password}
              onChange={e => setUserInfo({ ...userInfo, password: e.target.value })}
              placeholder='Password...'
              type={showPass ? 'text' : 'password'}
              icon={showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
              onClick={() => setShowPass(!showPass)}
              classname='login-text-password'
            />
            <StyledCheckbox
              label='Remember me'
              checked={userInfo.rememberMe}
              onChange={(e) => setUserInfo({ ...userInfo, rememberMe: e.target.checked })}
            />
            <div className="text-links">
              <div className="forgot-pass-field">
                <TextLink
                  to='/recover-account/'
                  className='login-nav-links'
                >
                  Forgot your password? Click here to reset it.
                </TextLink>
              </div>
              <div className="forgot-pass-field">
                <TextLink
                  to='/resend-activation/'
                  className='login-nav-links'
                >
                  Did not get your activation link yet? Click here to re-send it.
                </TextLink>
              </div>
            </div>
            <div className="login-btn">
              <PrimaryButton style={{ width: 140, height: 45 }} disabled={!enabled}>
                Login
              </PrimaryButton>
            </div>
            <div className="login-integrations">
              <div className="line"></div>
              <Typography variant='subheading2'>Or select one of the following social media to login </Typography>
              <div className="social-media">

              </div>
            </div>
          </div>
        </form>
      </div>
    </div >
  )
}

export default connectToStore(Login)