import React from "react"
import './ActivationSuccess.scss'
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import Typography from "../../../components/typography/Typography"
import CheckIcon from '@mui/icons-material/CheckCircleOutline'

const ActivationSuccess = ({ redirect, ...props }) => {
  return (
    <div className="activation-success">
      <div className="title">
        <CheckIcon />
        <Typography variant='h1 success'>You're all set up!</Typography>
      </div>
      <PrimaryButton
        onClick={redirect}
      >
        Take me to the dashboard!
      </PrimaryButton>
    </div>
  )
}

export default ActivationSuccess