import React from "react"
import './ResetPasswordSuccess.scss'
import Typography from "../../components/typography/Typography"
import CheckIcon from '@mui/icons-material/CheckCircleOutline'

const ResetPasswordSuccess = () => {

  return (
    <div className="reset-password-success">
      <div className="reset-success-header">
        <CheckIcon />
        <Typography variant='h1' className='success'>
          Email sent successfully!
        </Typography>
        <Typography variant='subheading1' className='success'>
          You should receive an email with a link in the next 10 minutes. Make sure to check the spam folder.
        </Typography>
      </div>
    </div>
  )
}

export default ResetPasswordSuccess