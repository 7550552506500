import { useEffect } from "react"
import { connect } from 'react-redux'
import { setLoggedIn } from '../redux/ducks/user'
import { useNavigate } from "react-router-dom"

const mapStateToProps = (state, ownProps) => ({
  user: state.user
})

const mapDispatchToProps = {
  setLoggedIn
}

const connectToStore = connect(
  mapStateToProps,
  mapDispatchToProps
)

const Logout = ({ user, setLoggedIn, ...props }) => {
  const navigate = useNavigate()

  useEffect(() => {
    window.localStorage.removeItem('jwt_access_token')
    window.localStorage.removeItem('jwt_refresh_token')
    setLoggedIn(false)
    navigate('/')
  }, [user.isLoggedIn])

  return (
    null
  )
}

export default connectToStore(Logout)