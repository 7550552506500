import React, { useRef } from "react"
import './PrintFrame.scss'
import FramePreview from "../qr-frame/FramePreview"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import SecondaryButton from "../../../components/buttons/SecondaryButton"
import ReactToPrint from 'react-to-print'

const PrintFrame = ({ frameSchema, closeModal }) => {
  const printRef = useRef()

  const FramePreviewRef = React.forwardRef(({ frameContent, ...props }, ref) => {
    return (
      <div ref={ref}><FramePreview frameContent={frameContent} /></div>
    )
  })

  return (
    <div className="print-frame-container">
      <FramePreviewRef
        ref={printRef}
        frameContent={frameSchema}
      />
      <div className="print-frame-nav">
        <ReactToPrint
          trigger={() =>
            <PrimaryButton>
              Print
            </PrimaryButton>}
          content={() => printRef.current}
        />

        <SecondaryButton
          onClick={closeModal}
        >
          Cancel
        </SecondaryButton>
      </div>
    </div>
  )
}

export default PrintFrame