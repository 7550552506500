import React, { useEffect, useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import './StyledSnackbar.scss'
import { connect } from 'react-redux'
import { clearSnackbar } from '../../redux/ducks/snackbar'

const mapDispatchToProps = {
  clearSnackbar
}

const connectToStore = connect(
  null,
  mapDispatchToProps
)

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const StyledSnackbar = ({ isOpen, severity, children, ...props }) => {
  const [open, setOpen] = useState(isOpen)

  useEffect(() => {
    const timeout = setTimeout(() => {
      props.clearSnackbar()
    }, 5000)
    // cleanup timeout
    return () => clearTimeout(timeout)
  }, [])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false)
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
      >
        {children}
      </Alert>
    </Snackbar>
  )
}

export default connectToStore(StyledSnackbar)