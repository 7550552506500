import React from "react"
import './EditorQr.scss'
import QRCode from "react-qr-code"
import Typography from "../typography/Typography"

const EditorQr = () => {
  return (
    <div className="editor-qr">
      <div className="editor-qr-header">
        <Typography variant='subheading3'>Edit field: QR Code</Typography>
      </div>
      <div className="qr-content">
        <QRCode
          value=''
        />
      </div>
    </div>
  )
}

export default EditorQr