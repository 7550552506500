import React from "react"
import { connect } from 'react-redux'
import { setLoggedIn, setUser, setCheckedAuth } from '../redux/ducks/user'
import { verifyUserToken, refreshUserToken } from "../api/user"
import { useEffect } from "react"

const mapStateToProps = (state, ownProps) => ({
  user: state.user
})

const mapDispatchToProps = {
  setUser,
  setLoggedIn,
  setCheckedAuth
}

const connectToStore = connect(
  mapStateToProps,
  mapDispatchToProps
)

const TokenRefresh = ({ user, setLoggedIn, setCheckedAuth, ...props }) => {
  const accessToken = window.localStorage.getItem('jwt_access_token')
  const refreshToken = window.localStorage.getItem('jwt_refresh_token')

  useEffect(() => {
    refreshUser()
  }, [])

  const refreshUser = () => {
    accessToken ?
      verifyUserToken({ token: accessToken })
        .then((res) => {
          setLoggedIn(true)
        })
        .catch((err) => {
          if (+err?.response?.status === 401) {
            window.localStorage.removeItem('jwt_access_token')
            if (refreshToken) {
              return refreshUserToken({ refresh: refreshToken })
                .then((res) => {
                  window.localStorage.setItem('jwt_access_token', res.data.access)
                  setLoggedIn(true)
                  return Promise.resolve()
                })
                .catch((err) => {
                  window.localStorage.removeItem('jwt_access_token')
                  window.localStorage.removeItem('jwt_refresh_token')
                  setLoggedIn(false)
                  return err
                })
            } else return false
          } else {
            window.localStorage.removeItem('jwt_access_token')
            setLoggedIn(false)
          }
        })
        .finally(() => {
          setCheckedAuth(true)
        })
      : setCheckedAuth(true)
  }
}

export default connectToStore(TokenRefresh)