import React, { useState } from 'react'
import './StyledToggle.scss'

const StyledToggle = ({ onChange, ...props }) => {
  return (
    <div className='styled-toggle'>
      <input type="checkbox" onClick={onChange} onChange={onChange} {...props} />
      <span onClick={onChange} onChange={onChange} />
    </div>
  )
}

export default StyledToggle
