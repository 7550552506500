import { axsWrapper } from './apiUtils'

const axs = axsWrapper('/')

export const getSitesList = () => {
  return axs.get(`/QR/site/`)
}

export const getSite = (id) => {
  return axs.get(`/QR/site/${id}/`)
}

export const postSite = (data) => {
  return axs.post(`/QR/site/`, data)
}

export const putSite = (id, data) => {
  return axs.put(`/QR/site/${id}/`, data)
}

export const deleteSite = (id) => {
  return axs.delete(`/QR/site/${id}/`)
}

export default axs