import React from "react"
import './ResetPassword.scss'
import StyledInput from "../../components/inputs/StyledInput"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import { NavLink } from "react-router-dom"
import Typography from '../../components/typography/Typography'
import SecondaryButton from '../../components/buttons/SecondaryButton'
import Logo from "../../components/logo/Logo"

const ResetPassword = ({ enabled, email, setEmail, onSubmit, ...props }) => {

  return (
    <div className="reset-password-container">
      <div className="reset-left">
        <div className="reset-left-content">
          <div className="logo"><Logo /></div>
          <div className="middle-message">
            <Typography variant='h1'>Reset your password</Typography>
            <Typography variant='h3'>If there is an account associated with your email address, you will receive an email to reset your password.</Typography>
          </div>
          <form className="reset-fields" onSubmit={onSubmit}>
            <StyledInput
              label='Email'
              value={email}
              onChange={e => setEmail(e.target.value)}
              type='email'
              className='reset-text-username'
              placeholder='Email...'
              required
            />
            <div className="reset-btn">
              <PrimaryButton disabled={!enabled}>
                Send reset link
              </PrimaryButton>
            </div>
          </form>
          <div className="create-account">
            <Typography variant='subheading2'>Need to log in instead?</Typography>
            <NavLink to='/login/'>
              <SecondaryButton variant='light'>Go to login</SecondaryButton>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword