export const fieldMapping = {
  'Text': {
    "type": "text",
    "label": "text field example",
    "style": {
      "labelColor": '',
      'fontSize': '22px',
      "textAlign": 'center',
    }
  },
  'Image': {
    "type": "image",
    "image": "",
  },
  'Email': {
    "type": "email",
    "required": false,
    "label": "email field example",
    "maxLimit": 50,
    "style": {
      "labelColor": '',
      "textboxColor": '',
      'fontSize': '14px'
    }
  },
  'Name': {
    "type": "name",
    "required": false,
    "label": "name field example",
    "maxLimit": 50,
    "style": {
      "labelColor": '',
      "textboxColor": '',
      'fontSize': '14px'
    }
  },
  'Comment': {
    "type": "comment",
    "required": false,
    "label": "comment field example",
    "maxLimit": 300,
    "style": {
      "labelColor": '',
      "textboxColor": '',
      'fontSize': '14px'
    }
  },
  'Rating': {
    "type": "rating",
    "required": false,
    "label": "rating field example",
    "max": 10,
    "style": {
      "labelColor": '',
      "starsColor": '',
      'fontSize': '14px'
    }
  }
}