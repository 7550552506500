import { axsWrapper } from './apiUtils'

const axs = axsWrapper('/')

export const getCodesList = () => {
  return axs.get(`/QR/qrcode/`)
}

export const getCode = (id) => {
  return axs.get(`/QR/qrcode/${id}/`)
}

export const postCode = (data) => {
  return axs.post(`/QR/qrcode/`, data)
}

export const putCode = (id, data) => {
  return axs.post(`/QR/qrcode/${id}/`, data)
}

export const deleteCode = (id) => {
  return axs.delete(`/QR/qrcode/${id}/`)
}

export default axs