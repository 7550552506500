import React, { useState } from "react"
import './ActivationSubscription.scss'
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import SecondaryButton from "../../../components/buttons/SecondaryButton"
import Typography from "../../../components/typography/Typography"
import CardButton from "../../../components/buttons/CardButton"
import CheckIcon from '@mui/icons-material/Check'

const ActivationSubscription = ({ setProgressActivation, progressActivation, ...props }) => {
  const [subscription, setSubscription] = useState(1)

  return (
    <div className="activation-subscription">
      <div className="title">
        <Typography variant='h1'>Subscription</Typography>
        <div className="line"></div>
        <Typography variant='body1'>Select your subscription type.</Typography>
        <Typography variant='body1'>The number of locations will add to the subscription you select.</Typography>
      </div>
      <div className="fields">
        <CardButton
          style={{ height: '350px', width: '200px' }}
          className={`${subscription === 0 ? 'selected' : ''}`}
          onClick={() => setSubscription(0)}
        >
          <div className="icon">{subscription === 0 && <CheckIcon />}</div>
        </CardButton>
        <CardButton
          style={{ height: '350px', width: '200px' }}
          className={`${subscription === 1 ? 'selected' : ''}`}
          onClick={() => setSubscription(1)}
        >
          <div className="icon">{subscription === 1 && <CheckIcon />}</div>
        </CardButton>
        <CardButton
          style={{ height: '350px', width: '200px' }}
          className={`${subscription === 2 ? 'selected' : ''}`}
          onClick={() => setSubscription(2)}
        >
          <div className="icon">{subscription === 2 && <CheckIcon />}</div>
        </CardButton>
      </div>
      <div className="nav-btns">
        <SecondaryButton
          onClick={() => setProgressActivation(progressActivation - 1)}
        >
          Previous
        </SecondaryButton>
        <PrimaryButton
          onClick={() => setProgressActivation(progressActivation + 1)}
        >
          Continue
        </PrimaryButton>
      </div>
    </div>
  )
}

export default ActivationSubscription