import React from "react"
import './ColorPicker.scss'
import classnames from 'classnames'

const ColorPicker = ({ className, id, ...props }) => {
  return (
    <label
      htmlFor={id}
      className={classnames('color-picker', className)}
      style={{ backgroundColor: props.value }}
    >
      <input
        id={id}
        title='pick color'
        type="color"
        {...props}
      />
    </label>
  )
}

export default ColorPicker