import React, { useState } from "react"
import './AccountActivation.scss'
import Typography from "../../../components/typography/Typography"
import Logo from "../../../components/logo/Logo"
import CheckIcon from '@mui/icons-material/CheckCircleOutline'
import StyledCircularProgress from "../../../components/progress-bar/StyledCircularProgress"
import ActivationCompany from "./ActivationCompany"
import ActivationLocations from "./ActivationLocations"
import ActivationPayment from "./ActivationPayment"
import ActivationSuccess from "./ActivationSuccess"
import { useNavigate } from "react-router-dom"
import { connect } from 'react-redux'
import { setUser } from '../../../redux/ducks/user'
import ActivationSubscription from "./ActivationSubscription"

const mapStateToProps = (state, ownProps) => ({
  user: state.user
})

const mapDispatchToProps = {
  setUser
}

const connectToStore = connect(
  mapStateToProps,
  mapDispatchToProps
)

const progressArray = ['Company info', 'Locations', 'Subscription', 'Payment']

const AccountActivation = ({ user, setUser, ...props }) => {
  const [progressActivation, setProgressActivation] = useState(0)
  const navigate = useNavigate()


  const redirectToDashboard = () => {
    setUser({ ...user, isLoggedIn: true })
    navigate('/')
  }

  return (
    <div className="account-activation-container">
      <div className="activation-left">
        <div className="activation-left-content">
          <div className="logo"><Logo /></div>
          <div className="middle-message">
            <CheckIcon />
            <Typography variant='h3' className='success'>Your account was successfully activated!</Typography>
            <Typography variant='subheading2'>Before you continue your journey, we need some more information</Typography>
          </div>
          <div className="activation-progress">
            <StyledCircularProgress
              progressArray={progressArray}
              rotation='vertical'
              current={progressActivation}
            />
          </div>
        </div>
      </div>
      <div className="activation-right">
        {progressActivation === 0 &&
          <ActivationCompany
            progressActivation={progressActivation}
            setProgressActivation={setProgressActivation}
          />
        }
        {progressActivation === 1 &&
          <ActivationLocations
            progressActivation={progressActivation}
            setProgressActivation={setProgressActivation}
          />
        }
        {progressActivation === 2 &&
          <ActivationSubscription
            progressActivation={progressActivation}
            setProgressActivation={setProgressActivation}
          />
        }
        {progressActivation === 3 &&
          <ActivationPayment
            progressActivation={progressActivation}
            setProgressActivation={setProgressActivation}
          />
        }
        {progressActivation === 4 &&
          <ActivationSuccess
            redirect={redirectToDashboard}
          />
        }
      </div>
    </div>
  )
}

export default connectToStore(AccountActivation)