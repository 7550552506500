import React from "react"
import './ResetPassword.scss'
import { useState } from "react"
import { recoverAcc } from "../../api/user"
import ResetPassword from "./ResetPassword"
import ResetPasswordSuccess from "./ResetPasswordSuccess"
import { setError } from '../../redux/ducks/snackbar'
import { connect } from 'react-redux'

const mapDispatchToProps = {
  setError
}

const connectToStore = connect(
  null,
  mapDispatchToProps
)

const ResetPasswordContainer = ({ setError, ...props }) => {
  const [disabledNav, setDisabledNav] = useState(false)
  const [hasSentEmail, setHasSentEmail] = useState(false)
  const [email, setEmail] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()
    setDisabledNav(true)
    recoverAcc({ email: email })
      .then((res) => {
        setHasSentEmail(true)
      })
      .catch((err) => {
        if (err.response.status === 400) {
          setError('There was an error when sending the email.\nThe email address is invalid.')
        }
      })
      .finally(() => {
        setDisabledNav(false)
      })
  }

  const enabled = !disabledNav && email.length > 1

  if (hasSentEmail) return <ResetPasswordSuccess />

  return (
    <ResetPassword
      onSubmit={onSubmit}
      email={email}
      setEmail={setEmail}
      enabled={enabled}
    />
  )
}

export default connectToStore(ResetPasswordContainer)