import React from "react"
import './ResendActivationSuccess.scss'
import Typography from "../../../components/typography/Typography"
import CheckIcon from '@mui/icons-material/CheckCircleOutline'
import Logo from "../../../components/logo/Logo"
import { NavLink } from "react-router-dom"

const ResendActivationSuccess = ({ redirect, ...props }) => {
  return (
    <div className="resend-activation-success">
      <div className="header">
        <NavLink to='/'>
          <Logo />
        </NavLink>
      </div>
      <div className="title">
        <CheckIcon />
        <Typography variant='h1 success'>The link is on the way!</Typography>
        <Typography variant='subheading1 success'>You should receive the activation link in the next 10 minutes. Make sure to check your spam folder.</Typography>
      </div>
    </div>
  )
}

export default ResendActivationSuccess