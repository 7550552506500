import React from "react"
import './RegisterSuccess.scss'
import CheckIcon from '@mui/icons-material/CheckCircleOutline'

const RegisterSuccess = () => {
  return (
    <div className="register-success">
      <div className="register-success-top">
        <CheckIcon />Success!</div>
      <div className="register-success-text">
        You will receive an email to confirm your account creation.<br />
        You can use that link to activate your account.<br />
        Make sure to check your spam folder.<br />
        The link is available for 24h.
      </div>
    </div>
  )
}

export default RegisterSuccess