import React from "react"
import './CardButton.scss'
import classnames from 'classnames'

const CardButton = ({ className, children, ...props }) => {
  return (
    <button
      className={classnames('card-btn btn', className)}
      {...props}
    >
      {children}
    </button>
  )
}

export default CardButton