import React, { useState } from "react"
import './ActivationLocations.scss'
import StyledInput from "../../../components/inputs/StyledInput"
import Typography from "../../../components/typography/Typography"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import SecondaryButton from "../../../components/buttons/SecondaryButton"

const ActivationLocations = ({ setProgressActivation, progressActivation, ...props }) => {
  const [numLoc, setNumLoc] = useState('')

  const disabledNav = numLoc < 1 || numLoc.length < 1

  return (
    <div className="activation-locations">
      <div className="fields">
        <div className="title">
          <Typography variant='h1'>Locations</Typography>
          <div className="line"></div>
          <Typography variant='body1'>
            Choose how many locations you want to start with.
          </Typography>
          <Typography variant='body1 warning'>
            The subscription price depends on how many locations you select in this step. You need to have at least 1 location.
          </Typography>
        </div>
        <StyledInput
          label='Number of locations:'
          className='location-number'
          placeholder='E.g. 10...'
          type='number'
          min={1}
          value={numLoc}
          onChange={e => setNumLoc(e.target.value)}
          required
        />
        {/* <Typography variant='subheading1'>Add main location info:</Typography> */}

        {/* <StyledInput
          label='Location name'
          maxLength='100'
          placeholder='Type your location name...'
        />
        <StyledInput
          label='Location address'
          maxLength='100'
          placeholder='Type your location address...'
        /> */}
      </div>
      <div className="nav-btns">
        <SecondaryButton
          onClick={() => setProgressActivation(progressActivation - 1)}
        >
          Previous
        </SecondaryButton>
        <PrimaryButton
          disabled={disabledNav}
          onClick={() => setProgressActivation(progressActivation + 1)}
        >
          Continue
        </PrimaryButton>
      </div>
    </div>
  )
}

export default ActivationLocations