import React, { useState } from "react"
import './EditCompany.scss'
import Typography from "../../components/typography/Typography"
import PrimaryButton from '../../components/buttons/PrimaryButton'
import SecondaryButton from '../../components/buttons/SecondaryButton'

const DeleteCompany = ({ company, deleteCompany, closeModal, disableNav, ...props }) => {

  return (
    <div className="edit-company-container delete-company">
      <div className="edit-company-header">
        <Typography variant='subheading1'>Delete Company</Typography>
      </div>
      <div className="edit-company-fields">
        <Typography variant='subheading2 error'>Important: Deleting this company will delete all associated locations and codes!</Typography>
        <Typography variant='subheading2'>Are you sure you want to delete "{company.name}" ?</Typography>
      </div>
      <div className="edit-company-btns">
        <SecondaryButton
          onClick={closeModal}
          disabled={disableNav}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          disabled={disableNav}
          onClick={() => { deleteCompany(company.id) }}
        >
          Delete
        </PrimaryButton>
      </div>
    </div>
  )
}

export default DeleteCompany