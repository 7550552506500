import React, { useEffect, useState } from "react"
import './Companies.scss'
import PageContainer from "../../components/page-container/PageContainer"
import Typography from "../../components/typography/Typography"
import SimpleCard from "../../components/styled-cards/SimpleCard"
import CardButton from "../../components/buttons/CardButton"
import StyledModal from "../../components/modal/StyledModal"
import EditCompany from "./EditCompany"
import AddCompany from "./AddCompany"
import IconButton from "../../components/buttons/IconButton"
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import DeleteCompany from "./DeleteCompany"
import Skeleton from '@mui/material/Skeleton'
import { connect } from 'react-redux'
import { setSuccess, setError } from '../../redux/ducks/snackbar'
import { getCompanyList, getCompany, postCompany, putCompany, deleteCompany } from '../../api/companies'

const emptyCompany = {
  name: '',
  address: '',
  regNo: '',
  legalRep: ''
}

const Companies = ({ setError, setSuccess }) => {
  const [companyList, setCompanyList] = useState()
  const [selectedCompany, setSelectedCompany] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [hasErrorLoadingCompanies, setHasErrorLoadingCompanies] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isEditingCompany, setIsEditingCompany] = useState(false)
  const [isDeletingCompany, setIsDeletingCompany] = useState(false)
  const [isAddingCompany, setIsAddingCompany] = useState(false)
  const [disableNav, setDisableNav] = useState(false)
  const [disableBtns, setDisableBtns] = useState(false)

  useEffect(() => {
    getCompanies()
  }, [])

  const getCompanies = () => {
    setIsLoading(true)
    getCompanyList()
      .then((res) => {
        setCompanyList(res.data)
      })
      .catch((err) => {
        setHasErrorLoadingCompanies(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getCompanyDetails = (company) => {
    getCompany(company)
      .then((res) => {
        openModal(res.data)
        setSelectedCompany(res.data)
      })
      .catch((err) => {
        setError('Could not fetch company details!')
      })
  }

  const addNewCompany = (company) => {
    setDisableNav(true)
    postCompany(company)
      .then((res) => {
        closeModal()
        setSuccess('Company successfully added!')
        getCompanies()
      })
      .catch((err) => {
        setError('Company could not be added!')
      })
      .finally(() => {
        setDisableNav(false)
      })
  }

  const changeCompany = (company) => {
    setDisableNav(true)
    putCompany(company.id, company)
      .then((res) => {
        closeModal()
        setSuccess('Company updated successfully!')
        getCompanies()
      })
      .catch((err) => {
        setError('Company could not be updated!')
      })
      .finally(() => {
        setDisableNav(false)
      })
  }

  const removeCompany = (company) => {
    setDisableNav(true)
    deleteCompany(company)
      .then((res) => {
        closeModal()
        setSuccess('Company successfully removed!')
        getCompanies()
      })
      .catch((err) => {
        setError('Company could not be removed!')
      })
      .finally(() => {
        setDisableNav(false)
      })
  }

  const openModal = () => {
    setIsOpenModal(true)
  }

  const closeModal = () => {
    setSelectedCompany()
    setDisableBtns(false)
    setIsOpenModal(false)
    setIsEditingCompany(false)
    setIsDeletingCompany(false)
    setIsAddingCompany(false)
  }

  const addCompany = () => {
    setDisableBtns(true)
    setIsAddingCompany(true)
    openModal()
  }

  const editCompany = (company) => {
    setDisableBtns(true)
    getCompanyDetails(company.id)
    setIsEditingCompany(true)
  }

  const openRemoveCompany = (company) => {
    setDisableBtns(true)
    setSelectedCompany(company)
    openModal()
    setIsDeletingCompany(true)
  }

  if (isLoading) return <CompaniesSkeleton />
  if (hasErrorLoadingCompanies) return <ErrorLoadingCompanies />

  return (
    <PageContainer className='companies-container'>
      <Typography variant='h1'>
        Companies
      </Typography>
      <Typography variant='subheading3'>
        Here you can edit, remove or add companies.
      </Typography>

      <div className="companies-cards">
        <CardButton
          className='add-new-company'
          onClick={addCompany}
          disabled={disableBtns}
          style={{ width: '220px', height: '320px' }}
        >
          <AddIcon />
          Add Company
        </CardButton>
        {companyList?.map((company, i) => (
          <SimpleCard
            className='company-card'
            key={`company-${company.id}`}
            style={{ width: '220px', height: '320px' }}
          >
            <div className="top-card">
              <IconButton
                variant='filled'
                title='Delete'
                disabled={disableBtns}
                onClick={() => openRemoveCompany(company)}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                variant='filled'
                title='Edit'
                disabled={disableBtns}
                onClick={() => editCompany(company)}
              >
                <EditIcon />
              </IconButton>
            </div>
            <div className="bot-card">{company.name}</div>
          </SimpleCard>
        ))}
      </div>

      {isOpenModal &&
        <StyledModal
          open={isOpenModal}
          style={{ minHeight: '30%', width: '550px' }}
          onClose={closeModal}
        >
          {isEditingCompany &&
            <EditCompany
              company={selectedCompany}
              disableNav={disableNav}
              changeCompany={changeCompany}
              closeModal={closeModal}
            />}
          {isDeletingCompany &&
            <DeleteCompany
              disableNav={disableNav}
              company={selectedCompany}
              deleteCompany={removeCompany}
              closeModal={closeModal}
            />
          }
          {isAddingCompany &&
            <AddCompany
              disableNav={disableNav}
              company={emptyCompany}
              addNewCompany={addNewCompany}
              closeModal={closeModal}
            />}
        </StyledModal>}
    </PageContainer>
  )
}

const mapDispatchToProps = {
  setError,
  setSuccess
}

const connectToStore = connect(
  null,
  mapDispatchToProps
)

export default connectToStore(Companies)

const CompaniesSkeleton = () => {
  return (
    <PageContainer className='companies-container'>
      <div className="skeleton" style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '30px' }}>
        <Skeleton variant='rectangular' width='100%' height='50px' />
        <Skeleton variant='rectangular' width='100%' height='50px' />
        <Skeleton variant='rectangular' width={220} height={320} />
      </div>
    </PageContainer>
  )
}

const ErrorLoadingCompanies = () => {
  return (
    <div className="error-companies">
      Some issue when loading companies - error message.
    </div>
  )
}