export const frameSchema = {
  "style": {
    "backgroundColor": 'white',
    "color": 'black',
    "fontSize": '14px',
  },
  "frame": [
    {
      "id": 1,
      "type": "text",
      "label": "Text here",
      "style": {
        "textAlign": 'center',
        "font-size": '14px'
      }
    },
    {
      "id": 2,
      "type": "qr",
    },
    {
      "id": 3,
      "type": "image",
      "image": ''
    },
  ],
}