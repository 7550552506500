import React from "react"
import ColorPicker from "../../../../components/inputs/ColorPicker"
import './EditorSetOverallStyle.scss'
import Typography from "../../../../components/typography/Typography"
import IconButton from "../../../../components/buttons/IconButton"
import InfoIcon from '@mui/icons-material/InfoOutlined'

const EditorSetOverallStyle = ({ overallStyle, setOverallStyle, ...props }) => {

  return (
    <div className="editor-overall-set-style">
      <div className="editor-styling-header">
        <Typography variant='subheading3'>General styling</Typography>
        <IconButton
          title='info'
        >
          <InfoIcon />
        </IconButton>
      </div>
      {overallStyle?.backgroundColor &&
        <div className="styling bkg-color">
          <Typography variant='body1'>Background color:</Typography>
          <ColorPicker
            id='bkg-style'
            value={overallStyle?.backgroundColor}
            onChange={e => setOverallStyle({ ...overallStyle, backgroundColor: e.target.value })}
          />
        </div>
      }
      {overallStyle?.color &&
        <div className="styling text-color">
          <Typography variant='body1'>General text color:</Typography>
          <ColorPicker
            id='color-style'
            value={overallStyle?.color}
            onChange={e => setOverallStyle({ ...overallStyle, color: e.target.value })}
          />
        </div>
      }
      {overallStyle?.accentColor &&
        <div className="styling accent-color">
          <Typography variant='body1'>General accents color:</Typography>
          <ColorPicker
            id='accent-style'
            value={overallStyle?.accentColor}
            onChange={e => setOverallStyle({ ...overallStyle, accentColor: e.target.value })}
          />
        </div>
      }
    </div>
  )
}

export default EditorSetOverallStyle