import React from "react"
import './Logo.scss'

const Logo = () => {
  return (
    <div className="logo-container">
      maoni
    </div>
  )
}

export default Logo