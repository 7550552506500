import React, { useState } from "react"
import './ActivationCompany.scss'
import StyledInput from "../../../components/inputs/StyledInput"
import Typography from "../../../components/typography/Typography"
import PrimaryButton from "../../../components/buttons/PrimaryButton"

const ActivationCompany = ({ setProgressActivation, progressActivation, ...props }) => {
  const [companyDetails, setCompanyDetails] = useState({
    name: '',
    address: '',
    regNo: '',
    legalRep: ''
  })

  const disabledNav = Object.values(companyDetails).some(val => val.length < 1)

  return (
    <div className="activation-company">
      <div className="title">
        <Typography variant='h1'>Company info</Typography>
        <div className="line"></div>
        <Typography variant='body1'>This is your main company. You can add additional companies later, inside the dashboard.</Typography>
      </div>
      <div className="fields">
        <StyledInput
          label='Company name'
          value={companyDetails.name}
          onChange={(e) => setCompanyDetails({ ...companyDetails, name: e.target.value })}
          maxLength='100'
          placeholder='Type your company name...'
          required
        />
        <StyledInput
          label='Company address'
          value={companyDetails.address}
          onChange={(e) => setCompanyDetails({ ...companyDetails, address: e.target.value })}
          maxLength='100'
          placeholder='Type your company address...'
          required
        />
        <StyledInput
          label='Company reg. number'
          value={companyDetails.regNo}
          onChange={(e) => setCompanyDetails({ ...companyDetails, regNo: e.target.value })}
          maxLength='100'
          placeholder='Type your company reg. number...'
          required
        />
        <StyledInput
          label='Company legal representative'
          value={companyDetails.legalRep}
          onChange={(e) => setCompanyDetails({ ...companyDetails, legalRep: e.target.value })}
          maxLength='100'
          placeholder='Type your company legal representative...'
          required
        />
      </div>
      <div className="nav-btns">
        <PrimaryButton
          disabled={disabledNav}
          onClick={() => setProgressActivation(progressActivation + 1)}
        >
          Continue
        </PrimaryButton>
      </div>
    </div>
  )
}

export default ActivationCompany