import React, { useEffect, useState } from "react"
import './CreatorSetup.scss'
import PageContainer from "../../../components/page-container/PageContainer"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import CreatorSetupGeneralInfo from "../qr-editor-general/CreatorSetupGeneralInfo"
import SecondaryButton from "../../../components/buttons/SecondaryButton"
import { useParams } from "react-router-dom"
import { emptyTemplateQuiz } from "../../../mocks/qr-empty-template"
import { fullTemplateQuiz } from "../../../mocks/qr-full-template"
import QRFieldsEditor from "../qr-creation/qr-fields-editor/QRFieldsEditor"
import QrFrameEditor from "../qr-frame/QrFrameEditor"
import { frameSchema } from "../../../mocks/frameSchema"
import QRPublish from "../qr-creation/qr-publish/QRPublish"
import { useNavigate } from "react-router-dom"
import { connect } from 'react-redux'
import { setSuccess, setError } from '../../../redux/ducks/snackbar'
import { getCode, postCode } from "../../../api/qrCodes"
import { getSitesList } from "../../../api/sites"
import { Skeleton } from "@mui/material"

const creatorSteps = ['General', 'QR Fields Editor', 'Frame Editor', 'Preview & Publish']

const CreatorSetup = ({ setSuccess, setError, ...props }) => {
  const [qrTemplate, setQrTemplate] = useState(0)
  const [disableNav, setDisableNav] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [qrQuiz, setQrQuiz] = useState()
  const [currentProgress, setCurrentProgress] = useState(0)
  const [qrFrameContent, setQrFrameContent] = useState(frameSchema)
  const [isPublished, setIsPublished] = useState(false)
  const [locations, setLocations] = useState([])
  const [hasErrorLoadingCodes, setHasErrorLoadingCodes] = useState(false)
  const [hasErrorLoadingSites, setHasErrorLoadingSites] = useState(false)

  const qrId = useParams().id
  const navigate = useNavigate()

  useEffect(() => {
    qrId === 'new' ? createNewQrForm() : getQRForm()
  }, [qrId])

  const getQRForm = () => {
    getCode(qrId)
      .then((res) => {
        setQrQuiz(res.data.schema)
        getSitesList()
          .then((res) => {
            setLocations(res.data)
          })
          .catch((err) => {
            setHasErrorLoadingSites(true)
          })
      })
      .catch((err) => {
        setHasErrorLoadingCodes(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const createNewQrForm = () => {
    getSitesList()
      .then((res) => {
        setLocations(res.data)
        setQrQuiz({ ...qrQuiz, schema: fullTemplateQuiz.schema })
      })
      .catch((err) => {
        setHasErrorLoadingSites(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const setTemplate = (val) => {
    setQrTemplate(val)
    val === 0 && setQrQuiz({ ...qrQuiz, schema: fullTemplateQuiz.schema })
    val === 1 && setQrQuiz({ ...qrQuiz, schema: emptyTemplateQuiz.schema })
  }

  const publishSchemas = () => {
    setDisableNav(true)
    console.log(qrQuiz)
    postCode(qrQuiz)
      .then((res) => {
        console.log(res.data)
        setIsPublished(true)
        setSuccess('QR Code added successfully!')
        navigate('/creator/')
      })
      .catch((res) => {
        setError('QR Code could not be added!')
      })
      .finally(() => {
        setDisableNav(false)
      })
  }

  //const generalDisabled = qrName.length === 0 || qrLocation === ''
  if (isLoading) return <FormSkeleton />
  if (hasErrorLoadingCodes || hasErrorLoadingSites) return <ErrorLoadingCode />

  return (
    <PageContainer className='creator-setup'>
      {currentProgress === 0 && <CreatorSetupGeneralInfo
        qrId={qrId}
        isEditing={qrId !== 'new'}
        quiz={qrQuiz}
        locations={locations}
        qrTemplate={qrTemplate}
        setQuiz={setQrQuiz}
        setQrTemplate={setTemplate}
      />}
      {currentProgress === 1 && <QRFieldsEditor
        quiz={qrQuiz}
        setQuiz={setQrQuiz}
      />}
      {currentProgress === 2 && <QrFrameEditor
        qrFrameContent={qrFrameContent}
        setQrFrameContent={setQrFrameContent}
      />}
      {currentProgress === 3 && <QRPublish
        qrSchema={qrQuiz}
        frameSchema={qrFrameContent}
      />}
      <div className="creator-setup-nav">
        <SecondaryButton
          disabled={currentProgress <= 0 || disableNav}
          onClick={() => { setCurrentProgress(currentProgress - 1) }}
        >
          Back
        </SecondaryButton>
        {currentProgress < creatorSteps.length - 1 &&
          <PrimaryButton
            disabled={disableNav}
            onClick={() => { setCurrentProgress(currentProgress + 1) }}
          >
            Next
          </PrimaryButton>}
        {currentProgress === creatorSteps.length - 1 &&
          <PrimaryButton
            disabled={disableNav}
            onClick={publishSchemas}
          >
            Publish
          </PrimaryButton>}
      </div>
    </PageContainer >
  )
}

const mapDispatchToProps = {
  setError,
  setSuccess
}

const connectToStore = connect(
  null,
  mapDispatchToProps
)

export default connectToStore(CreatorSetup)

const ErrorLoadingCode = () => {
  return (
    <div className="error-companies">
      Some issue when loading companies - error message.
    </div>
  )
}

const FormSkeleton = () => {
  return (
    <PageContainer className='creator-setup'>
      <div className="skeleton" style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '30px' }}>
        <Skeleton variant='rectangular' width='100%' height='50px' />
        <Skeleton variant='rectangular' width='100%' height='40px' />
        <Skeleton variant='rectangular' width='300px' height='40px' />
        <Skeleton variant='rectangular' width='300px' height='100px' />
        <Skeleton variant='rectangular' width='300px' height='40px' />
      </div>
    </PageContainer>
  )
}