import React from "react"
import './QrCodeField.scss'
import QRCode from "react-qr-code"
import Typography from "../typography/Typography"

const QrCodeField = ({ slug, ...props }) => {
  return (
    <div className="qr-code-content">
      <QRCode
        value={slug}
      />
      <div className="subtitle">
        <Typography variant='body1' className="powered">powered by &nbsp;</Typography>
        <Typography variant='body1' className="maoni">maoni.eu</Typography>
      </div>
    </div>
  )
}

export default QrCodeField