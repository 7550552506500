import React, { useState } from "react"
import './CreatorSetupGeneralInfo.scss'
import Typography from "../../../components/typography/Typography"
import StyledDropdown from "../../../components/dropdown/StyledDropdown"
import StyledInput from "../../../components/inputs/StyledInput"
import WarningIcon from '@mui/icons-material/WarningAmberOutlined'
import CardButton from '../../../components/buttons/CardButton'
import CheckIcon from '@mui/icons-material/Check'

const CreatorSetupGeneralInfo = ({ qrId, isEditing, qrTemplate, setQrTemplate, locations, quiz, setQuiz, ...props }) => {
  const [quizGeneralInfo, setQuizGeneralInfo] = useState(quiz)
  const dropdownLocations = locations?.map(loc => ({ value: loc.id, item: loc.name }))

  return (
    <div className='creator-setup-general-info'>
      <Typography variant='h1'>QR Creator - General Info</Typography>
      <Typography variant='subheading3'>
        Lorem ipsum
      </Typography>
      <div className="creator-field creator-name">
        <StyledInput
          value={quiz?.name}
          label='Choose a name for your QR code:'
          required
          maxLength='30'
          placeholder='Type name...'
          onChange={(e) => setQuiz({ ...quiz, name: e.target.value })}
        />
      </div>
      <div className="creator-field creator-name">
        <StyledInput
          label='Add a description:'
          value={quiz?.note}
          placeholder='Type description...'
          type='textarea'
          maxLength='200'
          onChange={(e) => setQuiz({ ...quiz, note: e.target.value })}
        />
      </div>
      <div className="creator-field creator-dropdown">
        <Typography variant='body1'>Select the location where your QR code will be used: *</Typography>
        <StyledDropdown
          selected={quiz?.site || ''}
          placeholder='Select location...'
          list={dropdownLocations}
          handleSelectItem={(e) => setQuiz({ ...quiz, site: e })}
        />
        <Typography variant='body1 warning'>
          <WarningIcon />
          Locations with no remaining QR codes will be disabled and cannot be selected!
        </Typography>
      </div>


      {!isEditing && <div className="creator-field creator-template">
        <div className="creator-template-text">
          <Typography variant='body1'>Choose a template for your QR code:</Typography>
        </div>
        <div className="creator-template-card">
          <CardButton
            disabled={isEditing}
            onClick={() => setQrTemplate(0)}
            className={`${qrTemplate === 0 ? 'selected' : ''}`}
            style={{ width: '150px', height: '150px' }}
          >
            <div className="icon">{qrTemplate === 0 && <CheckIcon />}</div>
            <div>Default</div>
            <div>(1 of each fields)</div>
          </CardButton>
          <CardButton
            disabled={isEditing}
            className={`${qrTemplate === 1 ? 'selected' : ''}`}
            onClick={() => setQrTemplate(1)}
            style={{ width: '150px', height: '150px' }}
          >
            <div className="icon">{qrTemplate === 1 && <CheckIcon />}</div>
            <div>Empty</div>
            <div>(No fields added)</div>
          </CardButton>
        </div>
      </div>}
    </div>
  )
}

export default CreatorSetupGeneralInfo