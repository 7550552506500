import React, { useState } from "react"
import './ResendActivation.scss'
import Typography from "../../../components/typography/Typography"
import StyledInput from "../../../components/inputs/StyledInput"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import { NavLink } from "react-router-dom"
import SecondaryButton from "../../../components/buttons/SecondaryButton"
import Logo from "../../../components/logo/Logo"

const ResendActivation = ({ email, setEmail, disabledNav, onSubmit, ...props }) => {

  return (
    <div className="resend-activation-container">
      <div className="resend-activation">
        <div className="logo"><Logo /></div>
        <div className="middle-message">
          <Typography variant='h1'>Resend an activation link</Typography>
          <Typography variant='h3'>If there is an inactive account associated with your email address, you will receive an email to activate it.</Typography>
        </div>
        <form className="reset-fields" onSubmit={onSubmit}>
          <StyledInput
            label='Email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            type='email'
            className='reset-text-username'
            placeholder='Email...'
            required
          />
          <div className="reset-btn">
            <PrimaryButton disabled={disabledNav}>
              Resend activation link
            </PrimaryButton>
          </div>
        </form>
        <div className="create-account">
          <Typography variant='subheading2'>Need to log in instead?</Typography>
          <NavLink to='/login/'>
            <SecondaryButton variant='light'>Go to login</SecondaryButton>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default ResendActivation