import React, { useState } from "react"
import './EditorLivePreview.scss'
import QuizParser from "../../qr-viewer/QuizParser"
import Typography from "../../../components/typography/Typography"

const EditorLivePreview = ({ quiz, ...props }) => {
  const [isSubmitted, setIsSubmitted] = useState(false)

  const mockSendUserData = (userData) => {

  }

  return (
    <div className="editor-live-preview">
      <div className="frame">
        <QuizParser
          isDisabledNav={false}
          quiz={quiz}
          isSubmitted={isSubmitted}
          sendUserData={mockSendUserData}
        />
      </div>
    </div>
  )
}

export default EditorLivePreview