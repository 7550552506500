import React from "react"
import './DeleteQrCode.scss'
import Typography from "../../../components/typography/Typography"
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import SecondaryButton from '../../../components/buttons/SecondaryButton'

const DeleteQrCode = ({ disableNav, qrCode, deleteQrCode, closeModal, ...props }) => {

  return (
    <div className="delete-qr-code-container">
      <div className="delete-qr-header">
        <Typography variant='subheading1'>Delete QR Code</Typography>
      </div>
      <div className="delete-qr-fields">
        <Typography variant='subheading2'>Are you sure you want to delete "{qrCode.name}" ?</Typography>
      </div>
      <div className="delete-qr-btns">
        <SecondaryButton
          disabled={disableNav}
          onClick={closeModal}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          disabled={disableNav}
          onClick={() => { deleteQrCode(qrCode.id) }}
        >
          Delete
        </PrimaryButton>
      </div>
    </div>
  )
}

export default DeleteQrCode