import React, { useState } from "react"
import './QrFrameEditor.scss'
import EditorSchemaAddField from "../qr-creation/qr-fields-editor/EditorSchemaAddField"
import { fieldMapping } from "../qr-creation/qr-fields-editor/fieldMapping"
import EditorDragDroppable from "../qr-creation/qr-fields-editor/EditorDragDroppable"
import { frameSchema } from "../../../mocks/frameSchema"
import FramePreview from "./FramePreview"
import Typography from "../../../components/typography/Typography"
import EditorSetOverallStyle from "../qr-creation/qr-fields-editor/EditorSetOverallStyle"

const QrFrameEditor = ({ qrFrameContent, setQrFrameContent, ...props }) => {
  const fields = ['Text', 'Image']
  const overallStyle = qrFrameContent?.style

  const addField = (field) => {
    let schema = { ...qrFrameContent }
    let currentSchema = [...qrFrameContent.frame]
    const selectedField = fields[field]
    const fieldObject = { ...fieldMapping[selectedField] }
    fieldObject.id = Date.now()
    currentSchema.push(fieldObject)
    schema.frame = currentSchema
    setQrFrameContent(prevSchema => ({ ...prevSchema, ...schema }))
  }

  const changeSchemaData = (val) => {
    let schema = { ...qrFrameContent }
    let currentSchema = [...qrFrameContent.frame]
    const currentId = val.id
    const currentField = currentSchema.findIndex(x => x.id === currentId)
    currentSchema[currentField] = val
    schema.frame = currentSchema
    setQrFrameContent(prevSchema => ({ ...prevSchema, ...schema }))
  }


  const moveFields = (list) => {
    let schema = { ...qrFrameContent }
    schema.frame = list
    setQrFrameContent(prevSchema => ({ ...prevSchema, ...schema }))
  }

  const deleteField = (id) => {
    let schema = { ...qrFrameContent }
    let currentSchema = [...qrFrameContent.frame]
    const deletedElement = currentSchema.findIndex(el => el.id === id)
    currentSchema.splice(deletedElement, 1)
    schema.frame = currentSchema
    setQrFrameContent(prevSchema => ({ ...prevSchema, ...schema }))

  }

  const setOverallStyle = (field) => {
    let currentSchema = qrFrameContent
    currentSchema.style = field
    setQrFrameContent(prevSchema => ({ ...prevSchema, ...currentSchema }))
  }

  return (
    <div className="qr-frame-container">

      <Typography variant='h1'>QR Creator - QR Frame Editor</Typography>
      <Typography variant='subheading3'>
        Create your frame by adding images, text or setting colors. Drag and drop the fields to move their place around.
      </Typography>
      <div className="qr-frame-editor">
        <div className="qr-frame-editor-fields">
          <EditorSetOverallStyle
            overallStyle={overallStyle}
            setOverallStyle={setOverallStyle}
          />
          <EditorSchemaAddField
            addField={addField}
            fieldMapping={fieldMapping}
            fieldTypes={fields}
          />
        </div>
        <div className="qr-frame-content">
          <EditorDragDroppable
            list={qrFrameContent?.frame}
            overallStyle={overallStyle}
            deleteField={deleteField}
            changePageData={changeSchemaData}
            setList={moveFields}
          />
        </div>
        <div className="qr-frame-viewer">
          <FramePreview
            frameContent={qrFrameContent}
          />
        </div>
      </div>
    </div>
  )
}

export default QrFrameEditor