import { axsWrapper } from './apiUtils'

const axs = axsWrapper('/')

export const getCompanyList = () => {
  return axs.get(`/QR/company/`)
}

export const getCompany = (id) => {
  return axs.get(`/QR/company/${id}/`)
}

export const postCompany = (data) => {
  return axs.post(`/QR/company/`, data)
}

export const putCompany = (id, data) => {
  return axs.put(`/QR/company/${id}/`, data)
}

export const deleteCompany = (id) => {
  return axs.delete(`/QR/company/${id}/`)
}

export default axs