export const fitness1vestiar = {
  "id": 1,
  "name": 'fitness 1 vestiar',
  "location": 1,
  "schema": {
    "style": {
      "backgroundColor": '#ef620f',
      "color": "#F3EFF5",
      "accentColor": "#D6C3C9",
    },
    "pages": [
      {
        "content": [
          {
            "id": 1,
            "type": "image",
            "image": '/nr1fitness-logo.svg'
          },
          {
            "id": 4,
            "type": "email",
            "required": false,
            "label": "Adresa ta de email:",

          },
          {
            "id": 6,
            "type": "comment",
            "required": true,
            "label": "Cum a fost standardul vestiarului azi? \nSpune-ne aici ce crezi: ",
          },
          {
            "id": 7,
            "type": "rating",
            "required": true,
            "label": "Acordă o notă: ",
            "max": 10,
          },
          {
            "id": 3,
            "type": "text",
            "label": "* - câmpuri obligatorii",
          },
          {
            "id": 8,
            "type": "navigation",
            "label": "Trimite"
          }
        ]
      }
    ]
  }
}

export const fitness1reception = {
  "id": 1,
  "name": 'fitness 1 reception',
  "location": 1,
  "schema": {
    "style": {
      "backgroundColor": '#ef620f',
      "color": "#F3EFF5",
      "accentColor": "#D6C3C9",
    },
    "pages": [
      {
        "content": [
          {
            "id": 1,
            "type": "image",
            "image": '/nr1fitness-logo.svg'
          },
          {
            "id": 4,
            "type": "email",
            "required": false,
            "label": "Adresa ta de email:",

          },
          {
            "id": 6,
            "type": "comment",
            "required": true,
            "label": "Cum a fost vizita ta la Nr1 Fitness astăzi?\nSpune-ne aici: ",
          },
          {
            "id": 7,
            "type": "rating",
            "required": true,
            "label": "Acordă o notă: ",
            "max": 10,
          },
          {
            "id": 3,
            "type": "text",
            "label": "* - câmpuri obligatorii",
          },
          {
            "id": 8,
            "type": "navigation",
            "label": "Trimite"
          }
        ]
      }
    ]
  }
}

export const fitness1gym = {
  "id": 1,
  "name": 'fitness 1 reception',
  "location": 1,
  "schema": {
    "style": {
      "backgroundColor": '#ef620f',
      "color": "#F3EFF5",
      "accentColor": "#D6C3C9",
    },
    "pages": [
      {
        "content": [
          {
            "id": 1,
            "type": "image",
            "image": '/nr1fitness-logo.svg'
          },
          {
            "id": 4,
            "type": "email",
            "required": false,
            "label": "Adresa ta de email:",

          },
          {
            "id": 6,
            "type": "comment",
            "required": true,
            "label": "Cum este sala de sport azi? Lipsește ceva? Ceva probleme?\nAnunță-ne:",
          },
          {
            "id": 7,
            "type": "rating",
            "required": true,
            "label": "Acordă o notă: ",
            "max": 10,
          },
          {
            "id": 3,
            "type": "text",
            "label": "* - câmpuri obligatorii",
          },
          {
            "id": 8,
            "type": "navigation",
            "label": "Trimite"
          }
        ]
      }
    ]
  }
}