import React from "react"
import {
  DragDropContext, Droppable, Draggable,
} from 'react-beautiful-dnd'
import EditorFieldParser from "./EditorFieldParser"
import './EditorDragDroppable.scss'

const EditorDragDroppable = ({ fieldTypeCount, setFieldTypeCount, list, element, setList, deleteField, overallStyle, changePageData, ...props }) => {
  if (!list || list.length === 0) return ('No content was added yet!')

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result;
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const items = reorder(
      list,
      result.source.index,
      result.destination.index
    )
    setList(items)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            className="droppable-area"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {list.map((item, index) => (
              <div
                key={item.id}
                className={item.type === 'navigation' ? 'navigation' : "draggable-field"}
              >
                <Draggable
                  draggableId={item.id.toString()}
                  isDragDisabled={item.type === 'navigation'}
                  isDropDisabled={item.type === 'navigation'}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <EditorFieldParser
                        field={item}
                        fieldTypeCount={fieldTypeCount}
                        setFieldTypeCount={setFieldTypeCount}
                        setUserData={changePageData}
                        overallStyle={overallStyle}
                        deleteField={deleteField}
                      />
                    </div>
                  )}
                </Draggable>
              </div>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default EditorDragDroppable