import React from "react"
import './StyledCircularProgress.scss'
import CheckIcon from '@mui/icons-material/CheckOutlined'
import classnames from 'classnames'

const StyledCircularProgress = ({ progressArray, current, rotation, className, ...props }) => {

  return (
    <div className={classnames('circular-progress-container', rotation, className)}>
      <div className="line"></div>
      <div className="circular-progress-content">
        {progressArray?.map((num, i) => (
          <div
            key={num}
            className={`progress ${current >= i ? ' checked' : ''} ${current === i ? ' current' : ''}`}
          >
            <div className='progress-number'>
              {(current <= i) ? (i + 1) : <CheckIcon />}
            </div>
            <div className="progress-name">{num}</div>
          </div>
        ))}
      </div>
    </div >
  )
}

export default StyledCircularProgress