import React, { useState } from "react"
import './EditLocation.scss'
import Typography from "../../components/typography/Typography"
import StyledInput from '../../components/inputs/StyledInput'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import SecondaryButton from '../../components/buttons/SecondaryButton'
import StyledDropdown from "../../components/dropdown/StyledDropdown"
import TextLink from "../../components/buttons/TextLink"
import Checkbox from "../../components/checkbox/StyledCheckbox"

const AddLocation = ({ disableNav, location, addNewLocation, closeModal, companies, ...props }) => {
  const [loc, setLoc] = useState(location)

  const isNextDisabled = Object.values(loc).some(x => x === null || x === '') || disableNav
  return (
    <div className="edit-location-container">
      <div className="edit-location-header">
        <Typography variant='subheading1'>Add Location</Typography>
      </div>
      <div className="edit-location-fields">
        <StyledInput
          required
          maxLength='50'
          label='Location name:'
          onChange={(e) => { setLoc({ ...loc, name: e.target.value }) }}
          value={loc.name}
          placeholder='Set location name...'
        />
        <StyledInput
          required
          type='email'
          maxLength='50'
          label='Email address:'
          onChange={(e) => { setLoc({ ...loc, email: e.target.value }) }}
          value={loc.email}
          placeholder='Set email address linked to this location...'
        />
        <StyledInput
          required
          label='Location address:'
          onChange={(e) => { setLoc({ ...loc, physical_address: e.target.value }) }}
          value={loc.physical_address}
          placeholder='Set location address...'
        />
        <StyledInput
          required
          label='Note:'
          onChange={(e) => { setLoc({ ...loc, note: e.target.value }) }}
          value={loc.note}
          placeholder='Write a note...'
        />
        <div className="edit-location-company">
          <div className="edit-location-company-label">Link to company: *</div>
          <div className="edit-location-company-dd">
            <StyledDropdown
              list={companies}
              placeholder='Select company...'
              selected={loc.company}
              handleSelectItem={(e) => { setLoc({ ...loc, company: e }) }}
            />
          </div>
        </div>

      </div>
      <div className="edit-location-btns">
        <SecondaryButton
          onClick={closeModal}
          disabled={disableNav}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          onClick={() => { addNewLocation(loc) }}
          disabled={isNextDisabled}
        >
          Add
        </PrimaryButton>
      </div>
    </div>

  )
}

export default AddLocation