import React, { useState } from "react"
import StyledDropdown from "../../../../components/dropdown/StyledDropdown"
import './EditorSchemaAddField.scss'
import SecondaryButton from "../../../../components/buttons/SecondaryButton"
import Typography from "../../../../components/typography/Typography"
import WarningIcon from '@mui/icons-material/WarningAmberOutlined'

const EditorSchemaAddField = ({ fieldTypes, addField, fieldTypeCount, fieldMapping, fieldTypeLimit, ...props }) => {
  const [selectedField, setSelectedField] = useState('')
  const fieldTypeSelected = fieldMapping[fieldTypes[selectedField]]?.type
  const fieldTypeAtLimit = fieldTypeLimit && fieldTypeCount[fieldTypeSelected] >= fieldTypeLimit
  const disabled = fieldTypeAtLimit || selectedField === ''

  return (
    <div className="editor-schema-add-field">
      <div className="editor-add-header">
        <Typography variant='subheading3'>Add field</Typography>
      </div>
      <div className="editor-schema-add-field-dropdown">
        <StyledDropdown
          list={fieldTypes}
          selected={selectedField}
          handleSelectItem={setSelectedField}
          placeholder='Select field...'
        />
        <SecondaryButton
          disabled={disabled}
          onClick={() => addField(selectedField)}
        >
          Add
        </SecondaryButton>
      </div>
      <div className="add-warning">
        {fieldTypeAtLimit &&
          <Typography variant='body1 warning'>
            <WarningIcon />
            You already have a field of this type!
          </Typography>
        }
      </div>
    </div>
  )
}

export default EditorSchemaAddField