import React, { useState } from "react"
import './ActivationPayment.scss'
import SecondaryButton from "../../../components/buttons/SecondaryButton"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import Typography from "../../../components/typography/Typography"
import StyledCheckbox from "../../../components/checkbox/StyledCheckbox"
import TextLink from "../../../components/buttons/TextLink"

const ActivationPayment = ({ setProgressActivation, progressActivation, ...props }) => {
  const [checkTerms, setCheckTerms] = useState(false)

  const disabledNav = !checkTerms
  return (
    <div className="activation-payment-container">
      <div className="title">
        <Typography variant='h1'>Payment</Typography>
        <div className="line"></div>
        <Typography variant='body1'>
          Check the info provided to make sure everything is alright before proceeding to payment.
        </Typography>
      </div>
      <div className="fields">
        <Typography variant='body1'>Company name:</Typography>
        <Typography variant='body1'>Company address:</Typography>
        <Typography variant='body1'>Company reg. no.:</Typography>
        <Typography variant='body1'>Company legal:</Typography>
        <Typography variant='body1'>Locations:</Typography>
        <Typography variant='body1'>Subscription:</Typography>
        <Typography variant='subheading1'>Total pay/month:</Typography>
        <StyledCheckbox
          required
          checked={checkTerms}
          onChange={(e) => setCheckTerms(e.target.checked)}
          label={<>I agree to the <TextLink
            to='/terms-conditions/'
            className='register-nav-links'
          >
            Terms & Conditions
          </TextLink></>}
        />
      </div>
      <div className="nav-btns">
        <SecondaryButton
          onClick={() => setProgressActivation(progressActivation - 1)}
        >
          Previous
        </SecondaryButton>
        <PrimaryButton
          disabled={disabledNav}
          onClick={() => setProgressActivation(progressActivation + 1)}
        >
          Pay
        </PrimaryButton>
      </div>
    </div>
  )
}

export default ActivationPayment