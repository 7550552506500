import { PROXY_ADDRESS } from './apiConfig'
import axios from 'axios'
import store from '../redux/store.js'
import { setLoggedIn } from '../redux/ducks/user'

const axiosService = axios.create({
  baseURL: PROXY_ADDRESS
})
axiosService.interceptors.request.use(
  config => {
    const token = window.localStorage.getItem('jwt_access_token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      delete axiosService.defaults.headers.common.Authorization
    }
    return config
  },
  null
)

axiosService.interceptors.response.use(
  (res) => {
    // successful response, nothing to do here
    return Promise.resolve(res)
  },
  (err) => {
    // don't know if there's a use case for now
    return Promise.reject(err)
  }
)

export function handleTokenExpired(err, status, prefix) {
  // auth token refreshing
  if (+err?.response?.status === status && !err?.config?.isRetryRequest) {
    window.localStorage.removeItem('jwt_access_token')
    const refreshToken = window.localStorage.getItem('jwt_refresh_token')
    if (refreshToken) {
      return axiosService
        .post('/user/api/token/refresh/', { refresh: refreshToken })
        .then(
          res => {
            const newToken = res.data.access
            const newRefresh = res.data.refresh
            window.localStorage.setItem('jwt_access_token', newToken)
            window.localStorage.setItem('jwt_refresh_token', newRefresh)
            err.config.headers.Authorization = `${prefix}${newToken}`
            return Promise.reject(err)
          },
          err => {
            window.localStorage.removeItem('jwt_access_token')
            window.localStorage.removeItem('jwt_refresh_token')
            store.dispatch(setLoggedIn(false))
            return Promise.reject(err)
          })
    } else {
      return Promise.reject(err)
    }
  } else {
    return Promise.reject(err)
  }
}

export const attachToken = (axs, prefix) => {
  axs.interceptors.request.use(
    config => {
      const token = window.localStorage.getItem('jwt_access_token')
      if (token) {
        config.headers.Authorization = `${prefix}${token}`
      } else {
        delete axs.defaults.headers.common.Authorization
      }
      return config
    },
    null
  )
}

export const refreshWrapper = (axs, errorStatus, prefix) => {
  axs.interceptors.response.use(
    null,
    (err) => handleTokenExpired(err, errorStatus, prefix)
  )
}

export const retryWrapper = (axs, options) => {
  const retryStatusCode = options.retryStatusCode
  axs.interceptors.response.use(
    null,
    (error) => {
      const config = error.config
      if (!config.isRetryRequest && error.response.status === retryStatusCode) {
        config.isRetryRequest = true
        return new Promise((resolve) => {
          resolve(axs(config))
        })
      }
      return Promise.reject(error)
    })
}

export function axsWrapper(path, errorStatus = 401, tokenPrefix = 'Bearer ') {
  const axs = axios.create({
    baseURL: PROXY_ADDRESS + path
  })

  attachToken(axs, tokenPrefix)
  refreshWrapper(axs, errorStatus, tokenPrefix)
  retryWrapper(axs, { retryStatusCode: errorStatus })

  return axs
}
