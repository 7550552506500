import React from "react"
import './FramePreview.scss'
import FieldParser from "../../qr-viewer/FieldParser"

const FramePreview = ({ frameContent, ...props }) => {
  const frame = frameContent?.frame
  const overallStyle = frameContent?.style

  return (
    <div style={{ backgroundColor: overallStyle.backgroundColor, color: overallStyle.color }} className="frame-preview-container">
      {(frame.length < 1) ?
        <div className="page">
          No content was found in the page.
        </div>
        :
        frame.map((el, i) => (
          <FieldParser
            overallStyle={overallStyle}
            key={el.id + 'item'}
            field={el}
            id={el.id}
          />))
      }
    </div>
  )
}

export default FramePreview