import React from "react"
import StyledInput from "../../components/inputs/StyledInput"
import StyledRating from "../../components/rating/StyledRating"
import ImageViewer from "../../components/image/ImageViewer"
import TextViewer from "../../components/text/TextViewer"
import QRCode from "react-qr-code"
import QrCodeField from "../../components/qr/QrCodeField"

const FieldParser = ({ field, userData, setUserData, id, overallStyle, ...props }) => {
  const fieldType = field.type
  const fieldStyle = field.style

  if (fieldType === 'text') {
    return <TextViewer
      label={field.label}
      fieldStyle={fieldStyle}
      overallStyle={overallStyle}
    />
  }

  if (fieldType === 'image') {
    return <ImageViewer
      image={field?.image}
      fieldStyle={fieldStyle}
      overallStyle={overallStyle}
    />
  }

  if (fieldType === 'name' || fieldType === 'input') {
    return <StyledInput
      className='editor'
      type='input'
      fieldStyle={fieldStyle}
      overallStyle={overallStyle}
      required={field.required}
      maxLength={field.maxLimit}
      value={userData[fieldType] || ''}
      onChange={(e) => { setUserData(e.target.value, fieldType) }}
      label={field.label}
      placeholder={field.placeholder}
    />
  }

  if (fieldType === 'email') {
    return <StyledInput
      className='editor'
      type='email'
      fieldStyle={fieldStyle}
      overallStyle={overallStyle}
      maxLength={field.maxLimit}
      required={field.required}
      value={userData[fieldType] || ''}
      onChange={(e) => { setUserData(e.target.value, fieldType) }}
      label={field.label}
      placeholder={field.placeholder}
    />
  }

  if (fieldType === 'textarea' || fieldType === 'comment') {
    return <StyledInput
      className='editor'
      type='textarea'
      fieldStyle={fieldStyle}
      overallStyle={overallStyle}
      maxLength={field.maxLimit}
      label={field.label}
      required={field.required}
      value={userData[fieldType] || ''}
      onChange={(e) => { setUserData(e.target.value, fieldType) }}
      rows={4}
      placeholder={field.placeholder}
    />
  }

  if (fieldType === 'rating') {
    return <StyledRating
      className='editor'
      maxLength={field.maxLimit}
      label={field.label}
      number={userData[fieldType] || ''}
      onChange={(e) => { setUserData(e.target.value, fieldType) }}
      max={field.max}
      fieldStyle={fieldStyle}
      overallStyle={overallStyle}
      required={field.required}
    />
  }

  if (fieldType === 'qr') {
    return <QrCodeField slug='slug' />
  }

}

export default FieldParser