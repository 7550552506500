import React, { useState } from "react"
import './QRFieldsEditor.scss'
import Typography from "../../../../components/typography/Typography"
import { fieldMapping } from "./fieldMapping"
import EditorSchemaAddField from "./EditorSchemaAddField"
import EditorDragDroppable from "./EditorDragDroppable"
import EditorLivePreview from "../EditorLivePreview"
import EditorSetOverallStyle from "./EditorSetOverallStyle"

const maxFieldsLimit = 1
const QRFieldsEditor = ({ quiz, setQuiz, ...props }) => {
  // const [isLoading, setIsLoading] = useState(true)
  const [fieldTypeLimit, setFieldTypeLimit] = useState(maxFieldsLimit)
  const [selectedPage, setSelectedPage] = useState(0)
  const [fieldTypeCount, setFieldTypeCount] = useState({
    text: 0,
    image: 0,
    email: 0,
    name: 0,
    comment: 0,
    rating: 0,
    navigation: 0
  })
  const parsedQuiz = quiz?.schema
  const pages = parsedQuiz?.pages
  const overallStyle = parsedQuiz?.style
  const fields = ['Text', 'Image', 'Email', 'Name', 'Comment', 'Rating']
  // const params = useParams()
  // const qrId = params.id

  // useEffect(() => {
  //   // setEditorQuiz(quiz)
  //   setIsLoading(false)
  // }, [])

  const setOverallStyle = (field) => {
    let currentSchema = quiz
    currentSchema.schema.style = field
    setQuiz(prevSchema => ({ ...prevSchema, ...currentSchema }))
  }

  const setQuizFields = (pageNo, val) => {
    let currentSchema = quiz
    currentSchema.schema.pages[pageNo].content = val
    setQuiz(prevSchema => ({ ...prevSchema, ...currentSchema }))
  }

  const submitData = () => {
    console.log(quiz)
  }

  const addField = (field) => {
    let currentPageData = [...pages[selectedPage].content]
    const contentLength = currentPageData.length - 1
    const selectedField = fields[field]
    const fieldObject = { ...fieldMapping[selectedField] }
    fieldObject.id = Date.now()
    currentPageData.splice(contentLength, 0, fieldObject)
    setQuizFields(selectedPage, currentPageData)
  }

  const changePageData = (val) => {
    let currentPageData = [...pages[selectedPage].content]
    const currentId = val.id
    const currentField = currentPageData.findIndex(x => x.id === currentId)
    currentPageData[currentField] = val
    setQuizFields(selectedPage, currentPageData)
  }

  const deleteField = (id) => {
    let currentPageData = [...pages[selectedPage].content]
    const deletedElement = currentPageData.findIndex(el => el.id === id)
    const deletedElType = currentPageData[deletedElement].type
    let currentFieldTypeCount = { ...fieldTypeCount }
    currentFieldTypeCount = { [deletedElType]: currentFieldTypeCount[deletedElType] - 1 }
    setFieldTypeCount(prevSchema => ({ ...prevSchema, ...currentFieldTypeCount }))
    currentPageData.splice(deletedElement, 1)
    setQuizFields(selectedPage, currentPageData)
  }

  const moveFields = (list) => {
    let currentPageData = list
    setQuizFields(selectedPage, currentPageData)
  }

  // if (isLoading) return <StyledLoading />

  return (
    <div className="qr-editor-content">
      <div className="qr-editor-header">
        <Typography variant='h1'>QR Creator - QR Form Setup</Typography>
        <Typography variant='subheading3'>
          You can only have 1 field of each type. Drag and drop fields around to change the order or delete fields (this applies to all fields except for Navigation Button, which will appear on the bottom and cannot be deleted).
        </Typography>
        <Typography variant='subheading3'>
          Each field has customizable styling, but you can choose an overall styling that applies to all fields!
        </Typography>
      </div>
      <div className="qr-editor">
        <div className="editor-schema-parser-page">
          <EditorSetOverallStyle
            overallStyle={overallStyle}
            setOverallStyle={setOverallStyle}
          />
          <EditorSchemaAddField
            addField={addField}
            fieldMapping={fieldMapping}
            fieldTypeCount={fieldTypeCount}
            fieldTypes={fields}
            fieldTypeLimit={fieldTypeLimit}
          />
          {/* <EditorSchemaPageNav
            pages={pages}
            addPage={addPage}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
          /> */}
        </div>
        <div className="qr-editor-view-container">
          <EditorDragDroppable
            list={pages[selectedPage].content}
            overallStyle={overallStyle}
            setFieldTypeCount={setFieldTypeCount}
            fieldTypeCount={fieldTypeCount}
            deleteField={deleteField}
            changePageData={changePageData}
            setList={moveFields}
          />
        </div>
        <div className="qr-editor-preview-container">
          <EditorLivePreview
            quiz={quiz}
          />
        </div>
      </div>
    </div >
  )
}

export default QRFieldsEditor