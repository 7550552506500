import React, { useEffect, useState } from "react"
import './Locations.scss'
import PageContainer from "../../components/page-container/PageContainer"
import Typography from "../../components/typography/Typography"
import TextLink from "../../components/buttons/TextLink"
import SimpleCard from "../../components/styled-cards/SimpleCard"
import StyledDropdown from '../../components/dropdown/StyledDropdown'
import AddLocation from "./AddLocation"
import StyledModal from "../../components/modal/StyledModal"
import EditLocation from "./EditLocation"
import IconButton from "../../components/buttons/IconButton"
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'
import DeleteLocation from "./DeleteLocation"
import StyledInputIcon from '../../components/inputs/StyledInputIcon'
import SearchIcon from '@mui/icons-material/Search'
import PrimaryButton from "../../components/buttons/PrimaryButton"
import { getSitesList, getSite, postSite, putSite, deleteSite } from "../../api/sites"
import { getCompanyList } from '../../api/companies'
import Skeleton from '@mui/material/Skeleton'
import { connect } from 'react-redux'
import { setSuccess, setError } from '../../redux/ducks/snackbar'

const emptyLocation = {
  name: '',
  physical_address: '',
  company: '',
  email: '',
  note: ''
}

const Locations = ({ setError, setSuccess }) => {
  const [companyList, setCompanyList] = useState()
  const [locationsList, setLocationsList] = useState()
  const [selectedLocation, setSelectedLocation] = useState()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [selectedCompany, setSelectedCompany] = useState(0)
  const [isEditingLocation, setIsEditingLocation] = useState(false)
  const [isDeletingLocation, setIsDeletingLocation] = useState(false)
  const [isAddingLocation, setIsAddingLocation] = useState(false)
  const [hasErrorLoadingCompanies, setHasErrorLoadingCompanies] = useState(false)
  const [hasErrorLoadingSites, setHasErrorLoadingSites] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [disableNav, setDisableNav] = useState(false)
  const [disableBtns, setDisableBtns] = useState(false)
  const [dropdownCompanies, setDropdownCompanies] = useState([])

  useEffect(() => {
    getLocations()
  }, [])

  const getCompanies = () => {
    getCompanyList()
      .then((res) => {
        setCompanyList(res.data)
        const dropdownComp = res.data?.map(loc => ({ value: loc.id, item: loc.name }))
        setDropdownCompanies(dropdownComp)
      })
      .catch((err) => {
        setHasErrorLoadingCompanies(true)
      })
  }

  const getLocations = () => {
    setIsLoading(true)
    getSitesList()
      .then((res) => {
        setLocationsList(res.data)
        getCompanies()
      })
      .catch((err) => {
        setHasErrorLoadingSites(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getLocationDetails = (location) => {
    getSite(location)
      .then((res) => {
        openModal(res.data)
        setSelectedLocation(res.data)
      })
      .catch((err) => {
        setError('Could not fetch company details!')
      })
  }

  const addNewLocation = (location) => {
    setDisableNav(true)
    postSite(location)
      .then((res) => {
        closeModal()
        setSuccess('Location successfully added!')
        getLocations()
      })
      .catch((err) => {
        setError('Location could not be added!')
      })
      .finally(() => {
        setDisableNav(false)
      })
  }

  const changeLocation = (company) => {
    setDisableNav(true)
    putSite(company.id, company)
      .then((res) => {
        closeModal()
        setSuccess('Location updated successfully!')
        getLocations()
      })
      .catch((err) => {
        setError('Location could not be updated!')
      })
      .finally(() => {
        setDisableNav(false)
      })
  }

  const deleteLocation = (location) => {
    setDisableNav(true)
    deleteSite(location)
      .then((res) => {
        closeModal()
        setSuccess('Location successfully removed!')
        getLocations()
      })
      .catch((err) => {
        setError('Location could not be removed!')
      })
      .finally(() => {
        setDisableNav(false)
      })
  }

  const openModal = () => {
    setIsOpenModal(true)
  }

  const closeModal = () => {
    setSelectedLocation()
    setDisableBtns(false)
    setIsOpenModal(false)
    setIsEditingLocation(false)
    setIsDeletingLocation(false)
    setIsAddingLocation(false)
  }

  const editLocation = (location) => {
    setDisableBtns(true)
    getLocationDetails(location.id)
    setIsEditingLocation(true)
  }

  const openRemoveLocation = (location) => {
    setDisableBtns(true)
    setSelectedLocation(location)
    openModal()
    setIsDeletingLocation(true)
  }

  const addLocation = () => {
    setDisableBtns(true)
    setIsAddingLocation(true)
    openModal()
  }

  const filteredLocations = locationsList?.filter(loc => (selectedCompany > 0 ? loc.company === selectedCompany : loc))

  if (isLoading) return <LocationSkeleton />
  if (hasErrorLoadingSites || hasErrorLoadingCompanies) return <ErrorLoadingLocations />

  return (
    <PageContainer className='locations-container'>
      <Typography variant='h1'>
        Locations
      </Typography>
      <Typography variant='subheading3'>
        Here you can edit, remove or add locations connected to a company. A location is required before adding a QR code. To add locations, make sure you have an
        <TextLink to='/companies/'>&nbsp;existing company.</TextLink>
      </Typography>
      <div className="locations-top-actions">
        <div className="locations-filters">
          <div className="locations-dropdown">
            <Typography variant='body1'>Select company:</Typography>
            <StyledDropdown
              selected={selectedCompany}
              disabled={disableBtns}
              placeholder='All companies...'
              list={[{ item: 'All companies', value: 0 }, ...dropdownCompanies]}
              handleSelectItem={(e) => setSelectedCompany(e)}
            />
          </div>
          {/* <StyledInputIcon
            classname='locations-search'
            placeholder='Search for a location...'
            disabled={disableBtns}
            icon={<SearchIcon />}
          /> */}
        </div>
        <div className="locations-add-new">
          <PrimaryButton disabled={disableBtns} onClick={addLocation}>Add location</PrimaryButton>
        </div>
      </div>

      {filteredLocations?.length === 0 ?
        <div className="locations-cards not-found">No locations were found.</div>
        :
        <div className="locations-cards">
          <div className="location-card-headers">
            <div className="location-card-descriptors">
              <div className="location-card-name">
                <Typography variant='subheading3'>Name</Typography>
              </div>
              <div className="location-card-company">
                <Typography variant='subheading3'>Company</Typography>
              </div>
              <div className="location-card-codes">
                <Typography variant='subheading3'>Codes</Typography>
              </div>
            </div>
            <div className="location-card-actions">
              <Typography variant='subheading3'>Actions</Typography>
            </div>
          </div>
          <div className="location-card-rows">
            {filteredLocations?.map((loc, i) => (
              <LocationCard
                loc={loc}
                disableBtns={disableBtns}
                companyList={companyList}
                key={`location-${loc.id}`}
                editLocation={editLocation}
                openRemoveLocation={openRemoveLocation}
              />
            ))}
          </div>
        </div>
      }

      {isOpenModal &&
        <StyledModal
          open={isOpenModal}
          style={{ minHeight: '30%', width: '550px' }}
          onClose={closeModal}
        >
          {isEditingLocation &&
            <EditLocation
              location={selectedLocation}
              changeLocation={changeLocation}
              deleteLocation={deleteLocation}
              closeModal={closeModal}
              disableNav={disableNav}
              companies={dropdownCompanies}
            />}
          {isAddingLocation &&
            <AddLocation
              location={emptyLocation}
              addNewLocation={addNewLocation}
              closeModal={closeModal}
              disableNav={disableNav}
              companies={dropdownCompanies}
            />}
          {isDeletingLocation &&
            <DeleteLocation
              disableNav={disableNav}
              closeModal={closeModal}
              deleteLocation={deleteLocation}
              location={selectedLocation}
            />
          }
        </StyledModal>}
    </PageContainer>
  )
}

const mapDispatchToProps = {
  setError,
  setSuccess
}

const connectToStore = connect(
  null,
  mapDispatchToProps
)


export default connectToStore(Locations)

const LocationCard = ({ disableBtns, companyList, loc, editLocation, openRemoveLocation }) => {

  return (
    <SimpleCard
      key={`location-${loc.id}`}
      className='location-card'
      style={{ width: '100%', height: '55px' }}
    >
      <div className="location-card-data">
        <div className="card-location-name">
          {loc.name}
        </div>
        <div className="card-location-company">
          {companyList?.find(comp => loc.company === comp.id).name}
        </div>
        <div className="card-location-codes">
          1/{loc.availableCodes || '?'}
        </div>
      </div>
      <div className="location-card-actions">
        <IconButton
          title='Edit'
          disabled={disableBtns}
          onClick={() => editLocation(loc)}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          title='Delete'
          disabled={disableBtns}
          onClick={() => openRemoveLocation(loc)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </SimpleCard>
  )
}

const LocationSkeleton = () => {
  return (
    <PageContainer className='locations-container'>
      <div className="skeleton" style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '30px' }}>
        <Skeleton variant='rectangular' width='100%' height='50px' />
        <Skeleton variant='rectangular' width='100%' height='50px' />
        <Skeleton variant='rectangular' width='100%' height='50px' />
        <Skeleton variant='rectangular' width='100%' height='80px' />
      </div>
    </PageContainer>
  )
}

const ErrorLoadingLocations = () => {
  return (
    <div className="error-locations">
      Some issue when loading locations - error message.
    </div>
  )
}