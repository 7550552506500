import React from "react"
import './RegisterActivationSuccess.scss'
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import Typography from '../../../components/typography/Typography'
import CheckIcon from '@mui/icons-material/CheckCircleOutline'
import { NavLink } from "react-router-dom"
import Logo from "../../../components/logo/Logo"

const RegisterActivationSuccess = () => {
  return (
    <div className="register-activation-success">
      <div className="header">
        <NavLink to='/'>
          <Logo />
        </NavLink>
      </div>
      <div className="body">
        <CheckIcon />
        <div className="register-activation-header">
          <Typography variant='h1' className='success'>Activating the account was successful!</Typography>
          <Typography variant='subheading1' className='success'>You can now log in using your credentials!</Typography>
        </div>
        <NavLink to='/login/'>
          <PrimaryButton>Go to login page</PrimaryButton>
        </NavLink>
      </div>
    </div>
  )
}

export default RegisterActivationSuccess