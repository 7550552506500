import React from "react"
import './EditLocation.scss'
import Typography from "../../components/typography/Typography"
import PrimaryButton from '../../components/buttons/PrimaryButton'
import SecondaryButton from '../../components/buttons/SecondaryButton'

const DeleteLocation = ({ location, deleteLocation, closeModal, disableNav, ...props }) => {

  return (
    <div className="edit-location-container delete-company">
      <div className="edit-location-header">
        <Typography variant='subheading1'>Delete Location</Typography>
      </div>
      <div className="edit-location-fields">
        <Typography variant='subheading2 error'>Important: Deleting this location will delete all associated codes!</Typography>
        <Typography variant='subheading2'>Are you sure you want to delete "{location.name}" ?</Typography>
      </div>
      <div className="edit-location-btns">
        <SecondaryButton
          disabled={disableNav}
          onClick={closeModal}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          disabled={disableNav}
          onClick={() => { deleteLocation(location.id) }}
        >
          Delete
        </PrimaryButton>
      </div>
    </div>
  )
}

export default DeleteLocation