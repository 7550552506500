import React from "react"
import './QRPublish.scss'
import Typography from "../../../../components/typography/Typography"
import EditorLivePreview from "../EditorLivePreview"
import FramePreview from "../../qr-frame/FramePreview"

const QRPublish = ({ qrSchema, frameSchema, ...props }) => {
  return (
    <div className="qr-publish-container">
      <div className="qr-editor-header">
        <Typography variant='h1'>QR Creator - Preview and Publish</Typography>
        <Typography variant='subheading3'>
          Check the final result and publish your code!
        </Typography>
      </div>
      <div className="qr-publish-content">
        <div className="qr-publish-code">
          <EditorLivePreview
            quiz={qrSchema}
          />
        </div>
        <div className="qr-publish-frame">
          <FramePreview
            frameContent={frameSchema}
          />
        </div>
      </div>
    </div>
  )
}

export default QRPublish