import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  user: {
    isLoggedIn: false,
    checkedAuth: false,
    hasSetup: false
  }
}


const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = { ...state.user, ...action.payload }
    },
    setCheckedAuth(state, action) {
      state.checkedAuth = action.payload
    },
    setLoggedIn(state, action) {
      state.isLoggedIn = action.payload
    },
    setHasSetup(state, action) {
      state.hasSetup = action.payload
    },
  }
})

export const {
  setUser,
  setCheckedAuth,
  setLoggedIn,
  setHasSetup
} = userSlice.actions

export default userSlice.reducer
