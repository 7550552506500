import React from "react"
import './EditorText.scss'
import StyledInput from "../inputs/StyledInput"
import IconButton from "../buttons/IconButton"
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined'
import Typography from "../typography/Typography"
import StyledDropdown from "../dropdown/StyledDropdown"
import ColorPicker from "../inputs/ColorPicker"
import StyledToggle from "../toggle/StyledToggle"
import { fontSizes } from "./fontSizes"

const EditorText = ({ id, field, fieldType, fieldStyle, overallStyle, setField, label, deleteField, changeLabel, isRequired, setIsRequired, setFontSize, ...props }) => {

  const labelColor = fieldStyle?.labelColor || overallStyle.color
  const fontSize = fieldStyle?.fontSize || overallStyle.fontSize

  const setStyle = (st) => {
    setField({ ...field, style: st })
  }

  return (
    <div className="editor-text">
      <div className="editor-text-header">
        <Typography variant='subheading3'>Edit field: {fieldType}</Typography>
        <IconButton
          onClick={() => deleteField(id)}
          title='Delete'
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <div className="editor-text-fields">
        <div className="field edit-label">
          <Typography variant='subheading3'>Label:</Typography>
          <StyledInput
            value={label}
            placeholder='Type label...'
            onChange={e => setField({ ...field, label: e.target.value })}
          />
        </div>

        <div className="edit-settings">
          <div className="field edit-font-size">
            <Typography variant='subheading3'>Text size:</Typography>
            <StyledDropdown
              selected={fontSizes.indexOf(fontSize)}
              placeholder='Size...'
              list={fontSizes}
              onChange={e => setStyle({ ...fieldStyle, fontSize: fontSizes[e.target.value] })}
            />
          </div>
        </div>
        <div className="edit-colors">
          <div className="field edit-label-color">
            <Typography variant='subheading3'>Label Color:</Typography>
            <ColorPicker
              id={id + 'label-color'}
              value={labelColor}
              onChange={e => setStyle({ ...fieldStyle, labelColor: e.target.value })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditorText