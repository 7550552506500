import React from "react"
import TextLink from '../../components/buttons/TextLink'
import './QrFormFooter.scss'

const QrFormFooter = () => {
  return (
    <div className="qr-form-footer">powered by
      <TextLink
        to='https://maoni.eu'
        target="_blank"
        rel="noreferrer"
      >
        maoni.eu
      </TextLink>
    </div>
  )
}

export default QrFormFooter