import React, { useState } from "react"
import './EditCompany.scss'
import Typography from "../../components/typography/Typography"
import StyledInput from '../../components/inputs/StyledInput'
import PrimaryButton from '../../components/buttons/PrimaryButton'
import SecondaryButton from '../../components/buttons/SecondaryButton'

const EditCompany = ({ company, changeCompany, closeModal, deleteCompany, disableNav, ...props }) => {
  const [comp, setComp] = useState(company)
  const isNextDisabled = Object.values(comp).some(x => x === null || x === '') || disableNav

  return (
    <div className="edit-company-container">
      <div className="edit-company-header">
        <Typography variant='subheading1'>Edit Company</Typography>
      </div>
      <div className="edit-company-fields">
        <StyledInput
          disabled={disableNav}
          maxLength='50'
          required
          label='Company name:'
          onChange={(e) => { setComp({ ...comp, name: e.target.value }) }}
          value={comp.name}
          placeholder='Set company name...'
        />
        <StyledInput
          disabled={disableNav}
          required
          label='Company address:'
          onChange={(e) => { setComp({ ...comp, address: e.target.value }) }}
          value={comp.address}
          placeholder='Set company address...'
        />
        <StyledInput
          disabled={disableNav}
          required
          label='Company reg number:'
          onChange={(e) => { setComp({ ...comp, regNo: e.target.value }) }}
          value={comp.regNo}
          placeholder='Set company reg number...'
        />
        <StyledInput
          disabled={disableNav}
          required
          label='Legal representative:'
          onChange={(e) => { setComp({ ...comp, legalRep: e.target.value }) }}
          value={comp.legalRep}
          placeholder='Set company representative...'
        />
      </div>
      <div className="edit-company-btns">
        <SecondaryButton
          onClick={closeModal}
          disabled={disableNav}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          disabled={isNextDisabled}
          onClick={() => changeCompany(comp)}
        >
          Save
        </PrimaryButton>
      </div>
    </div>
  )
}

export default EditCompany