import React from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import './StyledModal.scss'


const StyledModal = ({ open, onClose, children, style, ...props }) => {

  const styling = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    ...style
  }

  return (
    <Modal
      className='styled-modal'
      open={open}
      onClose={onClose}
    >
      <Box sx={styling}>{children}</Box>
    </Modal >
  )
}

export default StyledModal