import './App.scss'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom"
import Home from './pages/home/Home'
import Login from './pages/login/Login'
import { connect } from 'react-redux'
import RegisterContainer from './pages/register/RegisterContainer'
import Logout from './pages/Logout'
import Stats from './pages/stats/Stats'
import QrFeedback from './pages/qr-viewer/QrFeedback'
import Sidebar from './components/navbar/Sidebar'
import Companies from './pages/companies/Companies'
import Locations from './pages/locations/Locations'
import CreatorPage from './pages/qr-editor/qr-editor-setup/CreatorPage'
import CreatorSetup from './pages/qr-editor/qr-editor-setup/CreatorSetup'
import { isDevelopmentEnvironment } from './utils/environment'
import Storybook from './pages/storybook/Storybook'
import StyledSnackbar from './components/snackbar/StyledSnackbar'
import { clearSnackbar } from './redux/ducks/snackbar'
import Feedback from './pages/feedback/Feedback'
import Account from './pages/account/Account'
import AccountActivation from './pages/login/activation-setup/AccountActivation'
import RegisterActivationContainer from './pages/register/activation/RegisterActivationContainer'
import ResetPasswordContainer from './pages/reset-pass/ResetPasswordContainer'
import ResetPassSetNewContainer from './pages/reset-pass/set-new-pass/ResetPassSetNewContainer'
import TokenRefresh from './pages/TokenRefresh'
import StyledLoading from './components/loading/StyledLoading'
import ResendActivationContainer from './pages/register/resend-activation/ResendActivationContainer'

const mapStateToProps = (state, ownProps) => ({
  user: state.user,
  snackbar: state.snackbar
})

const mapDispatchToProps = {
  clearSnackbar
}

const connectToStore = connect(
  mapStateToProps,
  mapDispatchToProps
)

const App = ({ user, snackbar, clearSnackbar, ...props }) => {
  return (
    <div className="App">
      {/* {isDevelopmentEnvironment && <NavBar />} */}
      <div className='app-page-container'>
        {isDevelopmentEnvironment && user.isLoggedIn && <Sidebar />}
        <Routes>
          {isDevelopmentEnvironment &&
            <>
              <Route path='/' element={<Home />} />
              <Route path='/storybook/' element={<Storybook />} />
              {!user.isLoggedIn && <Route path='/login/' element={<Login />} />}
              {!user.isLoggedIn && <Route path='/resend-activation/' element={<ResendActivationContainer />} />}
              {!user.isLoggedIn && <Route path='/recover-account/' element={<ResetPasswordContainer />} />}
              {!user.isLoggedIn && <Route path='/reset-password' element={<ResetPassSetNewContainer />} />}
              {!user.isLoggedIn && <Route path='/register/' element={<RegisterContainer />} />}
              {!user.isLoggedIn && <Route path='/activate' element={<RegisterActivationContainer />} />}
              {user.isLoggedIn && <Route path='/account-setup/' element={<AccountActivation />} />}
              {user.isLoggedIn && <Route path='/logout/' element={<Logout />} />}
              {user.isLoggedIn && <Route path='/account/' element={<Account />} />}
              {user.isLoggedIn && <Route path='/creator/' element={<CreatorPage />} />}
              {user.isLoggedIn && <Route path='/creator/setup/:id/' element={<CreatorSetup />} />}
              {user.isLoggedIn && <Route path='/stats/' element={<Stats />} />}
              {user.isLoggedIn && <Route path='/companies/' element={<Companies />} />}
              {user.isLoggedIn && <Route path='/locations/' element={<Locations />} />}
              {user.isLoggedIn && <Route path='/feedback/' element={<Feedback />} />}
              <Route path='*' element={<Navigate to="/" />} />
            </>}
          <Route exact path='/qr-feedback' element={<QrFeedback />} />
        </Routes>
        {snackbar?.status && <StyledSnackbar isOpen={!!snackbar.status} severity={snackbar.status} children={snackbar.message} />}
      </div>
    </div>
  )
}

const Root = ({ user, snackbar, clearSnackbar, ...props }) => {
  return (
    <BrowserRouter>
      <TokenRefresh />
      {user.checkedAuth ?
        <App user={user} snackbar={snackbar} clearSnackbar={clearSnackbar} />
        :
        <StyledLoading />
      }
    </BrowserRouter>
  )
}

export default connectToStore(Root)


