import React from "react"
import './EditorInput.scss'
import StyledInput from '../inputs/StyledInput'
import IconButton from "../buttons/IconButton"
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined'
import Typography from "../typography/Typography"
import StyledDropdown from "../dropdown/StyledDropdown"
import ColorPicker from "../inputs/ColorPicker"
import StyledToggle from "../toggle/StyledToggle"
import { fontSizes } from "./fontSizes"

const EditorInput = ({ id, field, setField, fieldType, fieldStyle, overallStyle, label, deleteField, changeLabel, isRequired, setFontSize, setLabelColor, setTextboxColor, ...props }) => {

  const fontSize = fieldStyle?.fontSize || overallStyle.fontSize
  const textboxColor = fieldStyle?.textboxColor || overallStyle.accentColor
  const labelColor = fieldStyle?.labelColor || overallStyle.color

  const setStyle = (st) => {
    setField({ ...field, style: st })
  }

  return (
    <div className="editor-input-container">
      <div className="editor-input-header">
        <Typography variant='subheading3'>Edit field: {fieldType}</Typography>
        <IconButton
          onClick={() => deleteField(id)}
          title='Delete'
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <div className="editor-input-fields">
        <div className="field edit-label">
          <Typography variant='subheading3'>Label:</Typography>
          <StyledInput
            value={label}
            placeholder='Type label...'
            onChange={e => setField({ ...field, label: e.target.value })}
          />
        </div>
        <div className="edit-settings">
          <div className="field edit-required">
            <Typography variant='subheading3'>Required:</Typography>
            <StyledToggle
              checked={isRequired}
              onChange={() => setField({ ...field, required: !isRequired })}
            />
          </div>
          <div className="field edit-font-size">
            <Typography variant='subheading3'>Text size:</Typography>
            <StyledDropdown
              selected={fontSizes.indexOf(fontSize)}
              placeholder='Size...'
              list={fontSizes}
              onChange={e => setStyle({ ...fieldStyle, fontSize: fontSizes[e.target.value] })}
            />
          </div>
        </div>
        <div className="edit-colors">
          <div className="field edit-label-color">
            <Typography variant='subheading3'>Label Color:</Typography>
            <ColorPicker
              value={labelColor}
              onChange={e => setStyle({ ...fieldStyle, labelColor: e.target.value })}
            />
          </div>
          <div className="field edit-textbox-color">
            <Typography variant='subheading3'>Textbox Color:</Typography>
            <ColorPicker
              value={textboxColor}
              onChange={e => setStyle({ ...fieldStyle, textboxColor: e.target.value })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditorInput