import React, { useState } from "react"
import './Register.scss'
import StyledInput from "../../components/inputs/StyledInput"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined'
import Checkbox from "../../components/checkbox/StyledCheckbox"
import TextLink from "../../components/buttons/TextLink"
import StyledInputIcon from "../../components/inputs/StyledInputIcon"
import Logo from "../../components/logo/Logo"
import { NavLink } from "react-router-dom"
import SecondaryButton from "../../components/buttons/SecondaryButton"
import Typography from "../../components/typography/Typography"

const Register = ({ setHasSubmitted, userInfo, setUserInfo, disableNav, ...props }) => {
  const [showPass, setShowPass] = useState(false)
  const [passChecker, setPassChecker] = useState({
    capital: false,
    symbol: false,
    chars: false,
    digit: false
  })
  const [checkTerms, setCheckTerms] = useState(false)

  const changePass = (val) => {
    setPassChecker({
      capital: /[A-Z]/.test(val),
      symbol: /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(val),
      chars: val.length >= 10,
      digit: /[0-9]/.test(val)
    })
    setUserInfo({ ...userInfo, password: val })
  }

  const enabled = userInfo.firstName.length > 1 && userInfo.lastName.length > 1 && userInfo.email.length > 1 && checkTerms &&
    passChecker['capital'] && passChecker['symbol'] && passChecker['chars'] && passChecker['digit'] && !disableNav


  return (
    <div className="register-container">
      <div className="register-left">
        <div className="register-left-content">
          <div className="logo"><Logo /></div>
          <div className="middle-message">
            <Typography variant='h1'>Welcome!</Typography>
            <Typography variant='h3'>Please create an account to start your journey!</Typography>
          </div>
          <div className="login">
            <Typography variant='subheading1'>Already have an account?</Typography>
            <NavLink to='/login/'>
              <SecondaryButton variant='light'>Login</SecondaryButton>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="register-right">
        <div className="title">
          <Typography variant='h1'>Register</Typography>
        </div>
        <form onSubmit={setHasSubmitted}>
          <div className="register-fields">
            <StyledInput
              label='First name'
              required
              maxLength='100'
              value={userInfo.firstName}
              onChange={e => setUserInfo({ ...userInfo, firstName: e.target.value })}
              placeholder='Type your first name...'
              className='register-text-email'
            />
            <StyledInput
              label='Last name'
              required
              maxLength='100'
              value={userInfo.lastName}
              onChange={e => setUserInfo({ ...userInfo, lastName: e.target.value })}
              placeholder='Type your last name...'
              className='register-text-email'
            />
            <StyledInput
              label='Email'
              required
              maxLength='100'
              value={userInfo.email}
              onChange={e => setUserInfo({ ...userInfo, email: e.target.value })}
              placeholder='Type your email address...'
              type='email'
              className='register-text-email'
            />

            <StyledInputIcon
              label='Password'
              required
              value={userInfo.password}
              onChange={e => changePass(e.target.value)}
              placeholder='Password...'
              type={showPass ? 'text' : 'password'}
              icon={showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
              onClick={() => setShowPass(!showPass)}
              className='register-text-password'
            />
            <div className="pass-details">
              Your password needs to have:<br />
              <div className={passChecker['capital'] ? 'checked' : 'unchecked'}>○ at least 1 capital letter</div>
              <div className={passChecker['symbol'] ? 'checked' : 'unchecked'}>○ at least 1 symbol</div>
              <div className={passChecker['chars'] ? 'checked' : 'unchecked'}>○ at least 10 characters</div>
              <div className={passChecker['digit'] ? 'checked' : 'unchecked'}>○ at least 1 digit</div>
            </div>
            <Checkbox
              required
              checked={checkTerms}
              onChange={(e) => setCheckTerms(e.target.checked)}
              label={<>I agree to the <TextLink
                to='/terms-conditions/'
                className='register-nav-links'
              >
                Terms & Conditions
              </TextLink></>}
            />
            <div className="register-btn">
              <PrimaryButton
                style={{ width: 140, height: 45 }}
                disabled={!enabled}
              >
                Register
              </PrimaryButton>
            </div>
            <div className="register-integrations">
              <div className="line"></div>
              <Typography variant='subheading2'>Or select one of the following social media to register </Typography>
              <div className="social-media">

              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Register