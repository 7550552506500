import React from "react"
import './QuickFormPreview.scss'
import EditorLivePreview from "../qr-creation/EditorLivePreview"
import SecondaryButton from "../../../components/buttons/SecondaryButton"

const QuickFormPreview = ({ form, closeModal, ...props }) => {
  return (
    <div className="quick-form-preview-container">
      <div className="form">
        <EditorLivePreview quiz={form} />
      </div>
      <SecondaryButton
        onClick={closeModal}
      >
        Close
      </SecondaryButton>
    </div>
  )
}

export default QuickFormPreview