import React from "react"
import './EditorPrimaryBtn.scss'
import StyledInput from '../inputs/StyledInput'
import Typography from "../typography/Typography"
import StyledDropdown from "../dropdown/StyledDropdown"
import ColorPicker from "../inputs/ColorPicker"
import { fontSizes } from "./fontSizes"

const EditorPrimaryBtn = ({ fieldType, setField, field, overallStyle, fieldStyle, label, deleteField, changeLabel, setFontSize, ...props }) => {

  const fontSize = fieldStyle?.fontSize || overallStyle.fontSize
  const btnColor = fieldStyle?.btnColor || overallStyle.accentColor
  const labelColor = fieldStyle?.labelColor || overallStyle.color

  const setStyle = (st) => {
    setField({ ...field, style: st })
  }

  return (
    <div className="editor-primary-btn">
      <div className="editor-btn-header">
        <Typography variant='subheading3'>Edit field: {fieldType}</Typography>
      </div>
      <div className="editor-btn-fields">
        <div className="field edit-label">
          <Typography variant='subheading3'>Label:</Typography>
          <StyledInput
            value={label}
            placeholder='Type label...'
            onChange={e => setField({ ...field, label: e.target.value })}
          />
        </div>
        <div className="field edit-font-size">
          <Typography variant='subheading3'>Text size:</Typography>
          <StyledDropdown
            selected={fontSizes.indexOf(fontSize)}
            placeholder='Size...'
            list={fontSizes}
            onChange={e => setStyle({ ...fieldStyle, fontSize: fontSizes[e.target.value] })}
          />
        </div>
        <div className="edit-colors">
          <div className="field edit-label-color">
            <Typography variant='subheading3'>Label Color:</Typography>
            <ColorPicker
              value={labelColor}
              onChange={e => setStyle({ ...fieldStyle, labelColor: e.target.value })}
            />
          </div>
          <div className="field edit-btn-color">
            <Typography variant='subheading3'>Button Color:</Typography>
            <ColorPicker
              value={btnColor}
              onChange={e => setStyle({ ...fieldStyle, btnColor: e.target.value })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditorPrimaryBtn