import React, { useEffect } from "react"
import './EditorImg.scss'
import { useDropzone } from 'react-dropzone'
import IconButton from "../buttons/IconButton"
import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined'
import ImageIcon from '@mui/icons-material/BrokenImageOutlined'
import Typography from "../typography/Typography"

const EditorImg = ({ id, fieldType, field, setField, style, deleteField, ...props }) => {
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: acceptedFiles => {
      setField({
        ...field,
        image: acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))[0]
      })
    },
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg']
    },
    maxSize: 3072000,
    maxFiles: 1
  })

  useEffect(() => {
    if (field?.image) URL.revokeObjectURL(field?.image.preview)
  }, [field])

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div className="dropped-img-error" key={file.path}>
      <div className="dropped-img-name">{file.path} - {(file.size / (1024 * 1024)).toFixed(2)} MB</div>
      {errors.map(e => (
        e.message
      ))}
    </div>
  ))

  return (
    <div className="editor-img-container">
      <div className="editor-img-header">
        <Typography variant='subheading3'>Edit field: {fieldType}</Typography>
        <IconButton
          onClick={() => deleteField(id)}
          title='Delete'
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <div {...getRootProps({ className: 'img-container dropzone' })}>
        <input {...getInputProps()} />
        <div className='editor-img-droppable'>
          <Typography variant='body1'>Drag and drop an image or click to select from your computer</Typography>
        </div>
        {field?.image ?
          <div>
            <img className="dropped-img-preview" src={field?.image.preview} alt='dropped-img' />
            <div className="dropped-img-name">{field?.image.path} - {(field?.image.size / (1024 * 1024)).toFixed(2)} MB</div>
          </div>
          :
          <div className="drop-img-outline">
            <ImageIcon />
          </div>
        }
        <div className="dropped-img-instructions">
          <div className="instruction">The image must have max 3 MB.</div>
          <div className="instruction">Accepted file types are: png, jpg, jpeg.</div>
          <div className="instruction">The image will be automatically resized to 150px height.</div>
        </div>
        {fileRejectionItems}
      </div>
    </div>
  )
}

export default EditorImg

