import React from "react"
import './RegisterActivationError.scss'
import Typography from '../../../components/typography/Typography'
import PrimaryButton from '../../../components/buttons/PrimaryButton'
import ErrorIcon from '@mui/icons-material/ErrorOutline'
import Logo from "../../../components/logo/Logo"
import { NavLink } from "react-router-dom"

const mapErrorTypes = {
  400: 'Account already active!',
  401: 'The link is inactive or has expired!',
  404: 'There is no email associated with this token!'
}
const RegisterActivationError = ({ email, resendActivation, errorType, ...props }) => {
  return (
    <div className="register-activation-error">
      <div className="header">
        <NavLink to='/'>
          <Logo />
        </NavLink>
      </div>
      <div className="body">
        <ErrorIcon />
        <div className="register-activation-header">
          <Typography variant='h1' className='error'>Activating the account was unsuccessful!</Typography>
          <Typography variant='subheading1' className='error'>There was an issue when activating the account!</Typography>
          <Typography variant='subheading1' className='error'>{mapErrorTypes[errorType]}</Typography>
        </div>
        {errorType === 401 && email && <PrimaryButton onClick={resendActivation}>Resend Activation Email</PrimaryButton>}
      </div>
    </div>
  )
}

export default RegisterActivationError