import React, { useState } from "react"
import { setError } from '../../../redux/ducks/snackbar'
import { connect } from 'react-redux'
import ResendActivation from "./ResendActivation"
import ResendActivationSuccess from "./ResendActivationSuccess"
import { requestActivationLink } from "../../../api/user"

const mapDispatchToProps = {
  setError
}

const connectToStore = connect(
  null,
  mapDispatchToProps
)

const ResendActivationContainer = ({ setError, ...props }) => {
  const [disabledNav, setDisabledNav] = useState(false)
  const [sentEmail, hasSentEmail] = useState(true)
  const [email, setEmail] = useState('')

  const onSubmit = (e) => {
    setDisabledNav(true)
    e.preventDefault()
    requestActivationLink({ email: email })
      .then((res) => {
        hasSentEmail(true)
      })
      .catch((err) => {
        if (err.response.status === 500) {
          setError('The email could not be sent. \nServer error, please try again later.')
        }
        else {
          setError(`The email could not be sent.\n${Object.values(err.response.data)}`)
        }
      })
      .finally(() => {
        setDisabledNav(false)
      })
  }


  if (sentEmail) return <ResendActivationSuccess />
  const disabled = disabledNav || email.length < 1

  return (
    <ResendActivation
      email={email}
      setEmail={setEmail}
      onSubmit={onSubmit}
      disabledNav={disabled}
    />
  )
}

export default connectToStore(ResendActivationContainer)