import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import './StyledDropdown.scss'

// this will support either format depending on needs:
// ['1', '2', '3', '4', '5', '6'] and returns index
// { item: 'aaa', value: 1 }, { item: 'bbb', value: 2 } and returns value
const StyledDropdown = ({ width, selected, handleSelectItem, list, disabled, placeholder, className, ...props }) => {
  const [open, setOpen] = React.useState(false)
  const noPlaceholder = selected !== ''

  return (
    <Select
      className={`classic-dropdown ${open ? 'opened' : 'closed'}`}
      variant='standard'
      displayEmpty
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      disableUnderline
      disabled={disabled}
      renderValue={noPlaceholder ? undefined : () => <div className='placeholder'>{placeholder}</div>}
      value={selected}
      onChange={(e) => { handleSelectItem(e.target.value) }}
      MenuProps={{
        // disablePortal: true,
        className: 'classic-dropdown-menu-container',
        disableScrollLock: true
      }}
      {...props}
    >
      {list && list.map((option, index) => (
        <MenuItem
          className='classic-dropdown-list-item'
          key={`dd-${index}`}
          disableRipple
          value={option?.value || index}
        >
          <div className='classic-dropdown-list-item-text'>
            {option?.item || option}
          </div>
        </MenuItem>
      ))}
    </Select>
  )
}

export default StyledDropdown
